import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useLayoutEffect,
} from 'react';
// import TableSearch from '../../../common/components/TableSearch/TableSearch'
import { connect } from 'react-redux';
import { State } from '../../../rootReducer';
import { setTableFilter, setUsers, fetchUsers } from '../actions';
import { User } from '../../../common/types';
import DetailsRoundedIcon from '@material-ui/icons/DetailsRounded';
import ChangeHistoryRoundedIcon from '@material-ui/icons/ChangeHistoryRounded';
import { setOrder } from '../actions';
import styled from 'styled-components';
import SortIcon from '../../../common/components/SortIcon';
import Icon from "../../../common/components/Icon";
type Props = {
  tableFilter: string;
  setTableFilter: (filter: string) => void;
  setUsers: (users: User[]) => void;
  fetchUsers: (pageNum: number) => void;
  setOrder: any;
  isScroll: boolean;
};
const HeaderStyle = styled.div`
  display: flex;
  height: 32px;

  ${({ isScroll }) => isScroll && `margin-right:8px;`}
  svg {
  }
`;
const UsersTableHead: React.FC<Props> = ({
  tableFilter,
  setTableFilter,
  setUsers,
  fetchUsers,
  setOrder,
  isScroll,
}) => {
  const [timer, setTimer] = useState<ReturnType<typeof setTimeout> | null>(
    null
  );
  const [Clicked, setClicked] = useState<any>({ col: 'surname', count: 1 });

  const searchChangeHandler = useCallback(
    (filter: string) => {
      setTableFilter(filter);
      if (timer) clearTimeout(timer);
      setTimer(
        setTimeout(() => {
          setUsers([]);
          fetchUsers(1);
        }, 500)
      );
    },
    [fetchUsers, setTableFilter, setUsers, timer]
  );

  const firstUpdate = useRef(false);
  useLayoutEffect(() => {
    // при инициализации это не должно отрабатывать. для этого сделано условие с useRef
    if (firstUpdate.current) {
      setOrder(Clicked.col, Clicked.count === 1);
      return;
    }
    firstUpdate.current = true;
  }, [Clicked]);

  return (
    <HeaderStyle
      isScroll={isScroll}
      // className="grid table_head users_table_head"
    >
      <TableHeaderItem
        columnName="Сотрудник"
        columnId="surname"
        Clicked={Clicked}
        setClicked={(obj) => setClicked(obj)}
        style={{ width: '100%' }}
      />

      <TableHeaderItem
        columnName="Email"
        columnId="email"
        Clicked={Clicked}
        setClicked={(obj) => setClicked(obj)}
        style={{ maxWidth: '200px', minWidth: '200px' }}
      />

      <TableHeaderItem
        columnName="Должность"
        columnId="positions"
        Clicked={Clicked}
        setClicked={(obj) => setClicked(obj)}
        style={{ maxWidth: '200px', minWidth: '200px' }}
      />
       <TableHeaderItem
        columnName="Активность"
        columnId="is_active"
        Clicked={Clicked}
        setClicked={(obj) => setClicked(obj)}
        style={{ maxWidth: '200px', minWidth: '200px' }}
      />

      {/*<div className="table_search_wrapper">*/}
      {/*    <TableSearch searchValue={tableFilter} setSearchValue={searchChangeHandler} isOpen={!!tableFilter}/>*/}
      {/*</div>*/}
    </HeaderStyle>
  );
};

const HeaderItemName = styled.div`
  display: flex;
  position: relative;
  align-items: center;
`;
const TableHeaderItem = ({
  columnName,
  columnId,
  Clicked,
  setClicked,
  style,
}) => {
  return (
    <HeaderItemName style={style}>
      <div
        onClick={() => {
          setClicked({
            col: columnId,
            count:
              Clicked.col === columnId
                ? Clicked.count === 2
                  ? 1
                  : Clicked.count + 1
                : 1,
          });
        }}
        style={{ display: 'flex', position: 'relative', cursor: 'pointer' }}
      >
        {columnName}
        <SortIcon
          active={Clicked.col === columnId}
          activeSort={
            Clicked.col === columnId && Clicked.count === 1 ? 'top' : 'bottom'
          }
        />
      </div>

      {/* {Clicked.col === columnId && Clicked.count === 1 && (
        <DetailsRoundedIcon />
      )}
      {Clicked.col === columnId && Clicked.count === 2 && (
        <ChangeHistoryRoundedIcon />
      )} */}
    </HeaderItemName>
  );
};

const mapStateToProps = (state: State) => ({
  tableFilter: state.usersPage.tableFilter,
});

const mapDispatchToProps = {
  setTableFilter,
  setUsers,
  fetchUsers,
  setOrder,
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersTableHead);
