import { 
  getUsers, 
  getProjects, 
  getPositions
} from "../../common/selectors";
import { createSelector } from "reselect";
import { Task, Item } from "../../common/types";
import { State } from "../../rootReducer";

export const getSelectedTask = (state: State): Task | null =>
  state.taskInfoPlate.selectedTask;
export const getTaskInfoActiveTab = (state: State): string =>
  state.taskInfoPlate.taskInfoActiveTab;
export const getTaskObservers = (state: State): number[] =>
  state.taskInfoPlate.spectators;

const getProjectSections = (state: State) =>
  state.taskInfoPlate.projectSections;
const getProjectId = (state: State) => state.taskInfoPlate.project_id;
const getProjectSectionId = (state: State) =>
  state.taskInfoPlate.project_section_id;
const get_current_user_id = (state: State) => state.commonInfo.current_user;

export const getTaskObserversData = createSelector(
  [getUsers, getTaskObservers, getPositions, getSelectedTask],
  (users, spectators, positions, selectedTask) =>
  {
    //проверить и если надо будет вернуть
    // if(!selectedTask) return {list: [], selected: []};
    
    let selected: Item[] = [];
    let list = users.filter(user => {
      if(
        user.id === selectedTask?.author_id ||
        user.id === selectedTask?.executor_id
      )
        return false;

      return true;
    })
    .map(user => {
      const item = {
        urlImage: user.image?.url || false,
        text: `${user.surname} ${user.name}`,
        position: positions?.find(position => position?.id === user?.positions[0])?.name,
        value: user.id
      };

      if(spectators.includes(user.id)) selected?.push(item);

      return item;
    });

    return {list, selected};
  }
);

export const getUsersSelectList = createSelector(
  [getUsers, getSelectedTask, get_current_user_id],
  (users, selectedTask, current_user) => {
    let executor, author;
    let list = users.map((user) => {
      let listElem = {
        text: `${user.surname} ${user.name}`,
        value: user.id,
        urlImage: user?.image?.url,
      };

      if (selectedTask && user.id === selectedTask?.author_id)
        author = Object.assign({}, listElem);
      else if (current_user && user.id === current_user && !selectedTask) {
        author = Object.assign({}, listElem);
      }

      return listElem;
    });
    return { author, executor, list };
  }
);

export const getProjectsSelectList = createSelector(
  [getProjects, getSelectedTask, getProjectId],
  (projects, selectedTask, ProjectId) => {
    let selected: Item | null = null;
    let list = projects.map((project) => {
      let listElem = {
        text: project.name,
        value: project.id,
      };
      if (selectedTask && project.id === selectedTask?.project_id)
        selected = listElem;
      else if (ProjectId && project.id === ProjectId) selected = listElem; // new task

      return listElem;
    });

    return { selected, list };
  }
);

export const getSectionSelectList = createSelector(
  [getSelectedTask, getProjectSections, getProjectSectionId],
  (selectedTask, sections, ProjectSectionId) => {
    const sectionId = selectedTask?.project_section_id;
    let selected = null as Item | null;
    const list = sections.map((section) => {
      let item = {
        text: section.name,
        value: section.id,
      };
      if (sectionId && section.id === sectionId) {
        selected = item;
      } else if (ProjectSectionId && section.id === ProjectSectionId) {

        selected = item;
      }
      return item;
    });

    return { selected, list };
  }
);
