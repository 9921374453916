import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { MeetingListItem } from "../../../types";
import Accordion from "@material-ui/core/Accordion";
import { makeStyles } from "@material-ui/core/styles";
import { AccordionSummary } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import styled from "styled-components";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import MeetingNameCell from "../../../../../common/components/TableCells/MeetingNameCell";
import MeetingFormatCell from "../../../../../common/components/TableCells/MeetingFormatCell";
import {
  AccordionIcon2,
  AccordionIcon4,
  AccordionIcon6,
  AccordionIcon5,
  AccordionIcon7,
} from "../../../../../img/projectIcons";
import On_approval from "../../Icons/ImageWithColor/On_approval";
import Approved from "../../Icons/ImageWithColor/Approved";
import In_progress from "../../Icons/ImageWithColor/In_progress";
import Finished from "../../Icons/ImageWithColor/Finished";
import Canceled from "../../Icons/ImageWithColor/Canceled";
import IconChat from "../../../../../img/MeetingIcons/IconChat";
import Icon from "../../../../../img/MeetingIcons/Icon";
import Vector from "../../../../../img/MeetingIcons/Vector";
import MeetingSectionsTask from "./Questions/MeetingSectionsTask";
import MeetingMembers from "./Members/MeetingMembers";
import AccordionComments from "../../MeetingCard/components/Comments/AccordionComments";
import axios from "axios";
import { cookieMaster } from "../../../../../utils/CookieMaster";
import { User } from "../../../../../common/types";
import { State } from "../../../../../rootReducer";
import { connect } from "react-redux";
import { on } from "events";
import CreateQuestion from "./Questions/CreateQuestion";
import MeetingStatus from "./../Statuses/MeetingStatus";
import MemberStatus from "../Statuses/MemberStatus";
import Agreed from "../../Icons/ImageWithColor/ImgMemberStatus/Agreed";
import Refusal from "../../Icons/ImageWithColor/ImgMemberStatus/Refusal";
import Time from "../../Icons/ImageWithColor/ImgMemberStatus/Time";
import Tooltip from "@material-ui/core/Tooltip";
import { CreateNotif } from "../../../../../utils/createNotification";
import Pause from "../../Icons/ImageWithColor/ImgMemberStatus/Pause";
import TimeWindow from "../Statuses/TimeWindow";
import RefusedWindow from "../Statuses/RefusedWindow";

interface Props {
  changeMeeting: boolean;
  setChangeMeeting(v: boolean): void;
  // openStatus: boolean;
  // setOpenStatus(v: boolean): void;
  statusDataMember: boolean;
  setStatusDataMember: (v: boolean) => void;
  meeting: any;
  // editMeeting: () => void;
  // openAddQuestion: boolean;
  // setOpenAddQuestion: (mode: boolean) => void;
  deleteMeeting: (id: number) => void;
  commonUsers?: User[];
  setActiveMeeting: (meeting: any) => void;
  setIsEdit: (mode: boolean) => void;
  questions?: any;
  setQuestions?: (mode: any) => void;
  currentUser: User
}

const useStyles = makeStyles((theme) => ({
  MuiAccordionroot: {
    "&.MuiAccordion-root:before": {
      backgroundColor: "white",
    },
    "&.MuiAccordion-root:hover": {
      boxShadow: "0px 4px 5px #00000033 !important ",
      // color: "red",
      // backgroundColor: "red",
    },
  },
  MuiAccordionSym: {
    "&>.MuiAccordionSummary-content": {
      width: "100%",
    },
  },
}));

const AccrodHeaderDiv = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  justify-content: space-between;
  width: 100%;
  /* text-overflow: ellipsis; */
`;

const DivLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
`;

const DivIcons2 = styled.p(({ rotate }) => ({
  transform: rotate && "rotate(90deg)",
  width: "15px",
  height: "40px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginRight: "15px",
  marginLeft: "15.4px",
}));

const TextId = styled.p`
  display: block;
  color: #292a34;
  margin-right: 10px;
  font-size: 14px;
  margin-top: 6px;
  width: 45px;
  color: #a8a8a8;
  text-align: center;
`;

const DivRight = styled.div`
  display: flex;
  align-items: space-between;
  font-size: 16px;
  justify-content: space-between;
`;

const DivNumberTasks = styled.div`
  width: 100px;
  display: flex;
  align-items: center;
`;

const DivChat = styled.div`
  display: flex;
  align-items: center;
  padding: 15px;
  text-align: center;
`;

const ChatNum = styled.span`
  min-width: 20px;
  text-align: "center";
`;

const MeetingItem: React.FC<Props> = ({
  currentUser,
  changeMeeting,
  setChangeMeeting,
  statusDataMember,
  setStatusDataMember,
  meeting,
  deleteMeeting,
  // openAddQuestion,
  // setOpenAddQuestion,
  commonUsers,
  setActiveMeeting,
  setIsEdit,
  // questions,
  // setQuestions,
}) => {
  const classes = useStyles();

  const [openAccord, setOpenAccord] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorEl1, setAnchorEl1] = useState<null | HTMLElement>(null);
  const [anchorEl2, setAnchorEl2] = useState<null | HTMLElement>(null);
  const [questions, setQuestions] = useState<any>([]);
  const [openStatus, setOpenStatus] = useState(false);
  const [changeQuestion, setChangeQuestion] = useState<boolean>(false);
  const [openTime, setOpenTime] = useState<boolean>(false);
  const [openRefuse, setOpenRefuse] = useState<boolean>(false);
  const [valueQuestions, setValueQuestions] = useState<string>("");
  const [idForQuestion, setIdForQuestion] = useState<number>();
  const [openAddQuestion, setOpenAddQuestion] = useState<boolean>(false);
  const [isMember, setIsMember] = useState<boolean>(false);

  const userId = Number(localStorage.getItem("user_id"));

// Если это создатель совещания и он деактивирован, то полупрозрачный
  const checkOpacity = user => {
    if(!user) return false;

    return (user?.id === meeting?.initiator_id) && !user?.is_active;
  };

  // Если это не создатель совещания и он деактивирован, то бордер
  const checkBorder = user => {
    if(!user) return false;

    return (user?.id !== meeting?.initiator_id) && !user?.is_active;
  };

  const chairman = commonUsers?.find(
    (user) => user.id === meeting.chairman?.user_id
  );
  const secretary = commonUsers?.find(
    (user) => user.id === meeting.secretary?.user_id
  );
  const member = commonUsers?.find(
    (user) => user.id === meeting?.members[0]?.user_id
  );

  const getQuestions = async (id) => {
    try {
      const list = await axios.get(
        `${process.env.REACT_APP_PUBLIC_MEETING}/api/v1/meetings/${id}/questions`,
        {
          headers: {
            Authorization: `${cookieMaster.getCookie("access_token")}`,
          },
        }
      );
      setQuestions(list.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  // const getQuestionsWithId = useCallback(async (id) => {
  //   try {
  //     const list = await axios.get(
  //       `${process.env.REACT_APP_PUBLIC_MEETING}/api/v1/meetings/${id}/questions`,
  //       {
  //         headers: {
  //           Authorization: `${cookieMaster.getCookie("access_token")}`,
  //         },
  //       }
  //     );
  //     setQuestions(list.data.data);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }, [meeting.id, openAddQuestion, changeQuestion]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClick2 = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl1(event.currentTarget);
  };

  const handleClick3 = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (meeting.status !== 3 && meeting.status !== 4) {
      if (
        userId == meeting.initiator_id ||
        userId == meeting.chairman?.user_id ||
        userId == meeting.secretary?.user_id
      ) {
        localStorage.setItem("meetingId", meeting.id);
        localStorage.setItem("meetingStatus", meeting.status);
        setOpenStatus(!openStatus);
        setAnchorEl2(event.currentTarget);
      } /* else {
        CreateNotif(
          `Только инициатор, председатель или секретарь совещания могут изменять его статус!`,
          "warning"
        );
      } */
    }
  };

  const handleCheckStatus = ()=>{
    if (
      userId !== meeting.chairman?.user_id &&
      userId !== meeting.secretary?.user_id &&
      !meeting.members?.find((item)=> item.user_id == userId)
    ) {
      return false
    }
    else{
      return true
    }
  }

  const handleClose = (event: any) => {
    setAnchorEl(null);
    event.stopPropagation();
  };

  const meteengSection = [{}];

  const members = meeting.members.find((item) => item.user_id === userId);

  const statusMember = () => {
    if (meeting.chairman.user_id === userId) {
      return meeting.chairman?.status;
    } else if (meeting.secretary.user_id === userId) {
      return meeting.secretary?.status;
    } else if (members) {
      return members?.status;
    } else {
      return false;
    }
  };
  
  const isMemberHandler = () => {
    const isMember = meeting?.members.map(member => member.user_id).includes(currentUser?.id);

    setIsMember(isMember);
  };

  useEffect(() => {
   if(currentUser) {
     isMemberHandler();
   }
  }, [currentUser, meeting]);
  
  useEffect(() => {
    isMemberHandler();
    statusMember();
  }, [
    meeting.chairman?.status,
    meeting.secretary?.status,
    members?.status,
    meeting,
  ]);

  const addHandler = () => {
    const data = axios
      .post(
        `${process.env.REACT_APP_PUBLIC_MEETING}/api/v1/meetings/${idForQuestion}/questions`,
        {
          name: valueQuestions,
        },
        {
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: `${cookieMaster.getCookie("access_token")}`,
          },
        }
      )
      .then((res) => {
        setChangeQuestion(!changeQuestion);
        setOpenAddQuestion(false);
        getQuestions(idForQuestion);
        CreateNotif(`Повестка ${valueQuestions} успешно добавлена!`, "success");
      })

      .catch((e) => {
        CreateNotif(`Ошибка добавления повестки!`, "warning");
        setOpenAddQuestion(false);
        console.log(e);
      });
    setOpenAddQuestion(false);
    setValueQuestions("");
  };

  return (
    <>
      <CreateQuestion
        openAddQuestion={openAddQuestion}
        setOpenAddQuestion={setOpenAddQuestion}
        // idForQuestion={idForQuestion as number}
        addHandler={addHandler}
        valueQuestions={valueQuestions}
        setValueQuestions={setValueQuestions}
        key={meeting.id}
      />
      {openTime && (
        <TimeWindow
          meetingId={meeting.id}
          userId={userId}
          openTime={openTime}
          setOpenTime={setOpenTime}
          statusDataMember={statusDataMember}
          setStatusDataMember={setStatusDataMember}
        />
      )}
      {openRefuse && (
        <RefusedWindow
          meetingId={meeting.id}
          userId={userId}
          openRefuse={openRefuse}
          setOpenRefuse={setOpenRefuse}
          statusDataMember={statusDataMember}
          setStatusDataMember={setStatusDataMember}
        />
      )}
      <Accordion
        expanded={openAccord}
        elevation={0}
        classes={{
          root: classes.MuiAccordionroot,
        }}
        onClick={() => !openAccord && getQuestions(meeting.id)}
        onChange={(e, expanded) => setOpenAccord(expanded)}
        style={{
          width: "98%",
          margin: "0px 5px 10px 10px",
          boxShadow: "none",
          border: "none",
          borderRadius: "4px",
        }}
      >
        <AccordionSummary
          classes={{ root: classes.MuiAccordionSym }}
          style={{
            width: "100%",
            height: "84px",
            overflow: "hidden",
            padding: "0px 19px 0px 11px",
          }}
        >
          <AccrodHeaderDiv>
            <DivLeft>
              {/* делаем дизайн аналогично экрану "проекты" */}
              {/*  <div>
                <Vector />
            </div>  */}
              <DivIcons2 rotate={openAccord}>
                <AccordionIcon2 />
              </DivIcons2>

              <Button onClick={handleClick3}>
                {meeting.status == 0 && (
                  <Tooltip title={"На согласовании"} placement="top">
                    <span>
                      {" "}
                      <On_approval props={""} />{" "}
                    </span>
                  </Tooltip>
                )}
                {meeting.status == 1 && (
                  <Tooltip title="Cогласованно" placement="top">
                    <span>
                      {" "}
                      <Approved props={""} />{" "}
                    </span>
                  </Tooltip>
                )}

                {meeting.status == 2 && (
                  <Tooltip title="В процессе" placement="top">
                    <span>
                      {" "}
                      <In_progress />{" "}
                    </span>
                  </Tooltip>
                )}

                {meeting.status == 3 && (
                  <Tooltip title="Состоялось" placement="top">
                    <span>
                      {" "}
                      <Finished/>{" "}
                    </span>
                  </Tooltip>
                )}

                {meeting.status == 4 && (
                  <Tooltip title="Отменено" placement="top">
                    <span>
                      {" "}
                      <Canceled />{" "}
                    </span>
                  </Tooltip>
                )}
              </Button>

              <MeetingStatus
                openStatus={openStatus}
                setOpenStatus={setOpenStatus}
                changeMeeting={changeMeeting}
                setChangeMeeting={setChangeMeeting}
                meetingId={meeting.id}
                userId={userId}
                anchorEl1={anchorEl2}
                setAnchorEl1={setAnchorEl2}
              />

              <DivChat></DivChat>

              {/*<DivChat>
                <IconChat />
                <ChatNum>8</ChatNum>
                </DivChat> */}
              {/* делаем дизайн аналогично экрану "проекты" */}
              {/*   <div style={{marginRight:"10px"}}>
                <Icon />
                <span>2</span>
          </div> */}
              {/* <div></div> */}
              <div style={{ marginLeft: "10px" }}>
                <MeetingNameCell
                  id={meeting.id}
                  name={meeting.name}
                  desc={meeting.description}
                  width={true}
                />
              </div>
             {
              handleCheckStatus() && (

             <Button onClick={handleClick2}>
                {statusMember() == 0 && <Pause />}
                {statusMember() == 1 && <Agreed />}
                {statusMember() == 2 && <Refusal />}
                {statusMember() == 3 && <Time />}
              </Button>
              )
              
             } 
              {statusMember() == 0 ? (
                <MemberStatus
                  meetingId={meeting.id}
                  userId={userId}
                  anchorEl1={anchorEl1}
                  setAnchorEl1={setAnchorEl1}
                  handleClick2={handleClick2}
                  statusDataMember={statusDataMember}
                  setStatusDataMember={setStatusDataMember}
                  openTime={openTime}
                  setOpenTime={setOpenTime}
                  openRefuse={openRefuse}
                  setOpenRefuse={setOpenRefuse}
                  statusMember={statusMember}
                />
              ) : null}
            </DivLeft>

            <DivRight>
              <div>
                <div
                  className="logo_parent_v2"
                  style={{ minWidth: "160px", textAlign: "center" }}
                >
                  {chairman ? (
                    <div className="business__main-content__list-block__item__logo logo_task_1_v2"
                         style={{
                           opacity: checkOpacity(chairman) ? 0.5 : 1, 
                           border: checkBorder(chairman) ? "2px solid #fb8c00" : 0 
                         }}
                    >
                      {chairman && chairman.image ? (
                        <img
                          title={chairman?.name + chairman?.surname}
                          src={`${process.env.REACT_APP_PUBLIC_URL}/files/${chairman?.image.url}/download`}
                          alt="logo"
                        />
                      ) : (
                        <span title={chairman?.name + chairman?.surname}>
                          {chairman?.name[0] + chairman?.surname[0]}
                        </span>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  {secretary ? (
                    <div 
                      className="business__main-content__list-block__item__logo logo_task_2_v2"
                      style={{
                        opacity: checkOpacity(secretary) ? 0.5 : 1,
                        border: checkBorder(secretary) ? "2px solid #fb8c00" : 0
                      }}
                    >
                      {secretary && secretary.image ? (
                        <img
                          title={secretary.name + secretary.surname}
                          src={`${process.env.REACT_APP_PUBLIC_URL}/files/${secretary?.image.url}/download`}
                          alt="logo"
                        />
                      ) : (
                        <span title={secretary?.name + secretary?.surname}>
                          {secretary?.name[0] + secretary?.surname[0]}
                        </span>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  {member && (
                    <div className="business__main-content__list-block__item__logo logo_task_3_v2"
                         style={{
                           opacity: checkOpacity(member) ? 0.5 : 1,
                           border: checkBorder(member) ? "2px solid #fb8c00" : 0
                         }}
                    >
                      {member && member.image ? (
                        <img
                          title={member?.name + member?.surname}
                          src={`${process.env.REACT_APP_PUBLIC_URL}/files/${member?.image.url}/download`}
                          alt="logo"
                        />
                      ) : (
                        <span title={member?.name + member?.surname}>
                          {member?.name[0] + member?.surname[0]}
                        </span>
                      )}
                    </div>
                  )}
                  {meeting.members.length > 1 && (
                    <div
                      style={{ background: "#F4F4F4" }}
                      className="business__main-content__list-block__item__logo logo_task_4_v2 "
                    >
                      <span style={{ color: "rgba(41, 42, 52, 0.5)" }}>
                        +{meeting.members.length - 1}
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div
                style={{
                  minWidth: "175px",
                  textAlign: "center",
                  paddingTop: "10px",
                }}
              >
                {new Date(meeting.start_at).toLocaleString("ru", {
                  hour: "numeric",
                  minute: "numeric",
                  month: "short",
                  day: "numeric",
                  year: "numeric",
                })}
              </div>
              <div
                style={{
                  minWidth: "200px",
                  textAlign: "center",
                  paddingTop: "10px",
                }}
              >
                {meeting.format === 1 ? "Офлайн" : "Онлайн"}
              </div>
              <div style={{ textAlign: "center" }}>
                <MeetingFormatCell
                  id={1}
                  name={meeting.location}
                  desc={""}
                  width={true}
                />
                {/* {meeting.location}/ */}
              </div>

              {meeting.initiator_id === userId ||
                meeting.chairman.user_id === userId ||
                meeting.secretary.user_id === userId ||
                isMember
                ? (
                <Button onClick={handleClick}>
                  <AccordionIcon7 />
                </Button>
              ) : (
                <Button style={{ opacity: "0" }}>
                  <AccordionIcon7 />
                </Button>
              )}

              {meeting.initiator_id === userId ||
              meeting.chairman.user_id === userId ||
              meeting.secretary.user_id === userId ? (
                <Menu
                  anchorEl={anchorEl}
                  keepMounted={true}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  style={{ marginTop: "45px" }}
                >
                  <MenuItem
                    onClick={(e) => {
                      setIdForQuestion(meeting.id);
                      handleClose(e);
                      // editClickHandler();
                      setOpenAddQuestion(!openAddQuestion);
                    }}
                  >
                    Добавить повестку
                  </MenuItem>
                  <MenuItem
                    onClick={(e) => {
                      setActiveMeeting(meeting);
                      setIsEdit(true);
                      // editClickHandler();
                      handleClose(e);
                    }}
                  >
                    Редактировать
                  </MenuItem>
                  {meeting.initiator_id === userId ? (
                    <MenuItem
                      onClick={(e) => {
                        e.stopPropagation();
                        deleteMeeting(meeting.id);
                        setChangeMeeting(!changeMeeting); // чтобы список совещаний был в курсе что произошло изменение и перезагрузился
                        // editClickHandler();
                      }}
                    >
                      Удалить совещание
                    </MenuItem>
                  ) : null}
                </Menu>
              ) : (
                <>
                  {isMember && (
                    <Menu anchorEl={anchorEl}
                          keepMounted={true}
                          open={Boolean(anchorEl)}
                          onClose={handleClose}
                          style={{ marginTop: "45px" }}
                    >
                      <MenuItem onClick={(e) => {
                        setActiveMeeting(meeting);
                        setIsEdit(true);
                        handleClose(e);
                      }}>
                        Открыть
                      </MenuItem>
                    </Menu>
                  )}
                </>
              )}
            </DivRight>
          </AccrodHeaderDiv>
        </AccordionSummary>
        <AccordionDetails
          style={{
            background: "#FFFFFFF",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "0px 30px 0px 30px",
          }}
        >
          {questions?.map((item: any) => {
            return (
              <MeetingSectionsTask
                setOpenAccord={setOpenAccord}
                key={item.id}
                item={item}
                getQuestions={getQuestions}
                meeting={meeting}
                // meeting={meeting}
              />
            );
          })}
          <MeetingMembers
            meeting={meeting}
            setActiveMeeting={setActiveMeeting}
            setIsEdit={setIsEdit}
          />
          <AccordionComments meetingId={meeting.id} item={meeting} />{" "}
          {/* Комментарии и файлы */}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

const mapStateToProps = (state: State) => {
  return {
    commonUsers: state.commonInfo.users,
    currentUser: state.commonInfo.currentUserInfo
  };
};

export default connect(mapStateToProps)(MeetingItem);
