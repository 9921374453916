import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import BPModal from "../../../common/components/BPModalWindow/BPModalWindow";
import DeadLineCell from "../../../common/components/TableCells/DeadLineCell";
import ExecutorCell from "../../../common/components/TableCells/ExecutorCell";
import PriorityCell from "../../../common/components/TableCells/PriorityCell";
import TaskNameCell from "../../../common/components/TableCells/TaskNameCell";
import TaskWorkLoadingCell from "../../../common/components/TableCells/TaskWorkLoadingCell";
import TaskStatus from "../../../common/components/TaskStatus/TaskStatus";
import { Vector, Vector3 } from "../../../img/Task";
import { connect, useDispatch } from "react-redux";
import DateMaster from "../../../utils/DateMaster";
import { Task, User } from "../../../common/types";
import ProjectCell from "../../../common/components/TableCells/MeetingProjectSection";
import { urlApp } from "../../../App";
import { State } from "../../../rootReducer";
import { getSelectedTask } from "../../TaskInfoPlate/selectors";
import { selectTask, setShownTaskInfoPlate } from "../../TaskInfoPlate/actions";
import Button from "@material-ui/core/Button";
import { AccordionIcon7 } from "../../../img/projectIcons";
import TaskMenu from "../../Tasks/components/ui/TaskMenu/TaskMenu";

type Props = {
  task: Task;
  selectedTask: Task | null;
  selectTask: (task: Task) => void;
  setShownTaskInfoPlate: (show: boolean) => void;
  isParent?: boolean;
  counter?: number;
  isOpen?: boolean;
  setOpen?: (open: boolean) => void;
  users: User[];
  projects: object;
  partitions: object;
  activeColumns: string[];
  idOfTaskToShow: number;
  setIdOfTaskToShow: (value: number) => void;
  setShowSidePlate: (value: boolean) => void;
  currentUser
};

const ImgDiv = styled.div`
  padding-top: 2px;
  height: 30px;
  width: 30px;
  // background: rgb(244, 244, 244);
  text-align: center;
  &:hover {
    background: rgb(244, 244, 244);
  }
`;

const TextId = styled.p`
  display: block;
  color: #292a34;
  // margin-right: 10px;
  font-size: 14px;
  margin-top: 6px;
  width: 45px;
  color: #a8a8a8;
  text-align: center;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  margin-left: 40px;
`;
const TaskStyle = styled.div`
display: flex;
margin: 0 12px;
margin-bottom: 7px;
/* margin-right: 40px; */
justify-content: space-between;
min-height: 84px;
padding: 15px 0 15px 20px;
// box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
border-radius: 4px;
/* border-bottom: 1px solid; */
border-image: linear-gradient(to right, #ffffff, #e0e0e0, #e0e0e0, #ffffff)
  45% 0%;
background: #ffffff;
position: relative;
cursor: pointer;
transition: background-color 200ms ease;

&:hover {
  // background: linear-gradient(
  //   to right,
  //   white 0%,
  //   #f2f2f2 5%,
  //   #f2f2f2 90%,
  //   white 100%
  // );
  box-shadow: 0px 4px 5px rgb(0 0 0 / 20%);
}
${({ active }) =>
  active &&
  `
  background: linear-gradient(to right, white 0%,#F3FAFE 5%, #F3FAFE 90%, white 100%)!important;
  border-image: linear-gradient(to right,#ffffff,#99D7F5,#99D7F5,#ffffff ) 0% 5% 75% 7%;

  `}
`;
const BlockLeftFlex = styled.div`
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: center;
`;
const BlockRightFlex = styled.div`
  position: relative;
  display: flex;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ProjectAllSectionTasks: React.FC<any> = ({
  tasksState,
  activeColumns,
  projects,
  partitions,
  task,
  users,
  selectedTask,
  selectTask,
  setShownTaskInfoPlate,
  isParent = false,
  counter = 0,
  isOpen = false,
  setOpen = () => {},
  idOfTaskToShow,
  setIdOfTaskToShow,
  setShowSidePlate,
  currentUser
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement|null>(null);
  const [isTaskMenuOpen, setIsTaskMenuOpen] = useState<boolean>(false);

  const executor = users.find((user) => user.id === task.executor_id);
  const author = users.find((user) => user.id === task.author_id);

  const clickTaskActionsOpenHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsTaskMenuOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleSetAnchorEl = value => {
    if(!value) setIsTaskMenuOpen(false);

    setAnchorEl(value);
  };
  
  const clickHandler = (e: React.MouseEvent) => {
    e.stopPropagation();
    const target = e.target as HTMLElement;
    if (idOfTaskToShow !== +target.id) {
      setShowSidePlate(false);
      setIdOfTaskToShow(+target.id);
    } else if (idOfTaskToShow === +target.id) {
      setShowSidePlate(true);
      setIdOfTaskToShow(0);
    }
  };
  const dateMaster = useMemo(() => {
    return new DateMaster(task.end);
  }, [task.end]);
  
  const trClickHandler = (e: React.MouseEvent) => {
    if(isTaskMenuOpen) return;
    handleSetAnchorEl(null); // Скрываем меню три точки
    
    if (!(e.target as HTMLElement).closest(".task_status_wrapper"))
      setShownTaskInfoPlate(true);

    if (selectedTask?.id !== task.id) selectTask(task);
  }

  let project_of_task: string = "";
  Object.keys(projects).forEach((id) => {
    if (projects[id].id === task.project_id) {
      project_of_task = projects[id].name;
    }
  });

  let section_of_task: string = "";
  Object.keys(partitions).forEach((id) => {
    if (partitions[id].id === task.project_section_id) {
      section_of_task = partitions[id].name;
    }
  });

  return (
    <TaskStyle
      active={task.id === selectedTask?.id}
      onClick={trClickHandler}
      style={
        task?.last === true
          ? { border: "2px solid #01B0E9", borderRadius: "4px" }
          : null
      }
    >
      <BlockLeftFlex>
        <TextId>{task.id}</TextId>

        <div style={{ display: "flex", alignItems: "center " }}>
          <Vector3 />
        </div>
        <span
          style={{
            margin: "0px 12px 0px 5px",
            display: "flex",
            alignItems: "center ",
            width: "13px",
            color: "#94959A",
          }}
        >
          {task.comments_count}
        </span>
        <TaskNameCell
          id={task.id}
          name={task.name}
          desc={task.description}
          width={true}
        />
      </BlockLeftFlex>
      <BlockRightFlex>
        <div
          style={{ display: "flex", alignItems: "center", marginRight: "15px" }}
        >
          <ExecutorCell
            minWidth={"90px"}
            url={
              author?.image
                ? urlApp + `/files/${author.image.url}/download`
                : null
            }
            executorName={
              author ? `${author.surname} ${author.name}` : "Неизвестен"
            }
          />

          <ExecutorCell
            minWidth={"100px"}
            url={
              executor?.image
                ? urlApp + `/files/${executor.image.url}/download`
                : null
            }
            executorName={
              executor ? `${executor.surname} ${executor.name}` : "Неизвестен"
            }
          />
        </div>
        <div
          style={{ marginRight: "7px", display: "flex", alignItems: "center", width: "175px" }}
        >
          <TaskStatus // 571 задача из redmine. Из этого дочернего компонента- при начала работы с дочерним компонентом вернуть в родительский statusWindow= false.
            id={
              // 571 задача из redmine. При окончании- statusWindow = true
              // если статус "в работе" и дедлайн уже прошел, то надо ставить статус "просрочена" - id 14
              task.status_id === 10 &&
              new Date(
                dateMaster?.year,
                dateMaster?.month,
                dateMaster?.day,
                dateMaster?.hours,
                dateMaster?.minutes
              ).getTime() <= new Date(Date.now()).getTime()
                ? 14
                : task.status_id
            }
            taskId={task.id}
            id_bp={task.id_bp}
          />
        </div>
        <DeadLineCell
          end={task.end}
          deadline={dateMaster.deadlineMini}
          hot={task.hot}
          style={{ alignItems: "flex-start" }}
        />

        <TaskWorkLoadingCell
          taskLoad={task.task_load}
          workLoad={task.work_load}
          style={{ justifyContent: "flex-start", width: "95px", paddingLeft: "15px" }}
        />

        <PriorityCell
          style={{ backgroundColor: "transparent", width: "100px" }}
          priorityId={task.priority_id}
        />

        <div
          className='project-all-section-tasks__actions-wrapper'
          onClick={(e) => clickHandler(e)}
          id={task.id.toString()}
          style={{
            marginRight: "12px",
            marginTop: "17px",
            position: "relative",
            zIndex: 4,
          }}
        >
          {(currentUser?.isAdmin || (currentUser?.id === task.author_id)) &&
            <Button
              style={{ minWidth: '30px' }}
              onClick={( e ) => clickTaskActionsOpenHandler(e)}
            >
              <AccordionIcon7/>
            </Button>
          }
        </div>
      </BlockRightFlex>

      <TaskMenu
        id={task.id}
        taskIdBp={task?.id_bp}
        anchorEl={anchorEl}
        setAnchorEl={handleSetAnchorEl}
      />
    </TaskStyle>
  );
};
const mapStateToProps = (state: State) => {
  return {
    users: state.commonInfo.users,
    projects: state.commonInfo.projects,
    partitions: state.commonInfo.sections,
    activeColumns: state.projectsPage.activeColumns,
    selectedTask: getSelectedTask(state),
    currentUser: state.commonInfo.currentUserInfo
  };
};

const mapDispatchToProps = {
  selectTask,
  setShownTaskInfoPlate,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectAllSectionTasks);
