import React from "react";

const In_progress:React.FC = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="20" fill="#E0E0E0" />
      <path
        d="M27.0417 19.5192L16.9183 13.3325C16.8331 13.2804 16.7354 13.252 16.6355 13.2501C16.5356 13.2482 16.437 13.273 16.3499 13.3219C16.2627 13.3708 16.1901 13.442 16.1396 13.5282C16.0891 13.6144 16.0625 13.7126 16.0625 13.8125V26.1859C16.0625 26.2858 16.0891 26.384 16.1396 26.4702C16.1901 26.5564 16.2627 26.6276 16.3499 26.6765C16.437 26.7254 16.5356 26.7502 16.6355 26.7483C16.7354 26.7464 16.8331 26.718 16.9183 26.6659L27.0417 20.4792C27.1239 20.4289 27.1918 20.3584 27.239 20.2743C27.2861 20.1903 27.3109 20.0956 27.3109 19.9992C27.3109 19.9028 27.2861 19.8081 27.239 19.7241C27.1918 19.64 27.1239 19.5695 27.0417 19.5192Z"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default In_progress;
