// таблица задач в проекте

import React, { useEffect, useRef, useState } from "react";
import ProjectTableHead from "./ProjectAllTableHead";
import { State } from "../../../rootReducer";
import { connect, useSelector } from "react-redux";
import { Task, Project, IMeta } from "../../../common/types";
import { useParams } from "react-router-dom";
import { changeTaskList, fetchTasks } from "../actions";

import styled from "styled-components";
import ProjectAllTaskTr from "./ProjectAllTaskTr";
import { getProjects, setProjects } from "../../../common/actions";
import './projectAllTable.scss';
import PreloaderLocal from "../../../common/components/PreloaderLocal/PreloaderLocal";

type Props = {
  isFetchingTasks: boolean;
  tasks: Task[];
  getProjects: (pageNum: number) => void;
  projects: Project[];
  changeTaskList: (tasks: Task[]) => void;
  fetchTasks: (id: number, pageNum, abortController?: AbortController) => void;
  activeColumns: string[];
  isLoadingProjects: boolean,
  setProjects: (projects: Project[]) => void,
  projectsMeta: IMeta
};

const WrapperTasks = styled.div`
  overflow-y: scroll;
  height: calc(100vh - 240px);
  width: 100%;
`;
const WrapperTasksTable = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  position: relative;
  background-color: #f4f4f4;
`;

const ProjectAllTable: React.FC<Props> = ({
  activeColumns,
  tasks,
  projects,
  changeTaskList,
  fetchTasks,
  getProjects,
  isLoadingProjects,
  setProjects,
  projectsMeta
}) => {
  const userRole = useSelector((state: State) => state.commonInfo!.currentUserInfo);
  const isFetchingTasks = useSelector((state: State) => state.projectsPage.isFetchingTasks);

  const abortController = new AbortController();
  const { id }: any = useParams();
  const [pageNum, setPageNum] = useState(1);
  useEffect(() => {
    changeTaskList([]);
  }, [id, changeTaskList]);
  useEffect(() => {
    fetchTasks(id, 1, abortController);
  }, [fetchTasks, id]);

  useEffect(() => {
    getProjects(1);
    
    return () => {
      setProjects([]);
      
      abortController.abort();
    };
  }, []);
  
  const scrollHandler = (e) => {
    const { scrollHeight, scrollTop, clientHeight } = e.currentTarget;

    if(
      scrollHeight <= scrollTop + clientHeight &&
      pageNum < projectsMeta.last_page
    ) {
      setPageNum(pageNum + 1);
    }
  }

  const [isScroll, setScroll] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  
  useEffect(() => {
    if(pageNum !== 1) {
      getProjects(pageNum);
    }
  }, [fetchTasks, id, pageNum]);

  useEffect(() => {
    setPageNum(1);
  }, [id]);

  useEffect(() => {
    let widthScrol = ref?.current?.offsetWidth || 0;
    let widthTable = ref?.current?.scrollWidth || 0;

    if (widthScrol > widthTable) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  }, [ref]);

  return (
    <WrapperTasksTable isFetchingTasks={isFetchingTasks} className='projectAllTable' >
      <>
        {!!projects.length && (
          <ProjectTableHead setPageNum={setPageNum} isScroll={isScroll} />
        )}

        <div ref={ref} className='projectAllTable__wr'>
          <div className={
            `projectAllTable__preloader-wr ${isLoadingProjects 
              ? 'projectAllTable__preloader-wr--show' : 
              ''}`}
          >
            <div className="projectAllTable__preloader">
              <PreloaderLocal />
            </div>
          </div>

          {(!isLoadingProjects && projects.length === 0) && (
            <div className="main_title"
                 style={{paddingLeft: 24, fontSize: 24, background: '#fff'}}
            >
              Проекты отсутствуют
            </div>
          )}

          {!!projects.length && (
            <>
              <WrapperTasks className='projectAllTable__wrapper-tasks' onScroll={(e) => scrollHandler(e)}>
                {projects.map((project: Project) => (
                  <ProjectAllTaskTr key={project.id} project={project} />
                ))}
              </WrapperTasks>
            </>
          )}
        </div>
      </>
    </WrapperTasksTable>
  );
};

const mapStateToProps = (state: State) => ({
  activeColumns: state.projectsPage.activeColumns,
  isFetchingTasks: state.projectsPage.isFetchingTasks,
  tasks: state.projectsPage.tasks,
  projects: state.commonInfo.projects,
  isLoadingProjects: state.commonInfo.isLoadingProjects,
  projectsMeta: state.projectsPage.projectsMeta
});

const mapDispatchToProps = {
  changeTaskList,
  fetchTasks,
  getProjects,
  setProjects
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectAllTable);
