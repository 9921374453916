import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useLayoutEffect,
} from "react";
import { State } from "../../../rootReducer";
import {
  setTableFilter,
  changeTaskList,
  fetchTasks,
  setOrder,
} from "../actions";
import { connect } from "react-redux";
import {
  fetchTasksAction,
  setTableFilterAction,
  changeTaskListAction,
} from "../action_types";
import { Task } from "../../../common/types";

import styled from "styled-components";
import SortIcon from "../../../common/components/SortIcon";
import { setIsUpdateProject } from "../../NewProject/actions";

type Props = {
  tableFilter: string;
  projectId: number | null;
  setTableFilter: (filter: string) => setTableFilterAction;
  changeTaskList: (tasks: Task[]) => changeTaskListAction;
  fetchTasks: (projecId: number, pageNum: number) => fetchTasksAction;
  setOrder: any;
  activeColumns: string[];
  setPageNum: (pageNum: number) => void;
  isScroll: boolean;
  isUpdateProject: boolean;
  setIsUpdateProject: (mode: boolean) => void;
};

const WrapperTasksTableHead = styled.div`
  width: 98%;
  padding: 0px 35px 0px 9px;
  margin-right: ${({ isScroll }) => isScroll && "8px"};
  display: flex;
  height: 81px;
  justify-content: space-between;
`;

const BlockStyled = styled.div`
  overflow: hidden;
  display: flex;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #a8a8a8;
  align-items: center;
  padding-right: 5px;

  svg {
    width: 10px;
    height: 10px;
  }
`;

const HeaderItemName = styled.div`
  cursor: pointer;
  padding-left: 4px;
  & > span {
    position: relative;
  }
`;

const columnsOfTables = [
  {
    columnId: "id",
    showingText: "№",
    style: { minWidth: "90px", textAlign: "right" },
  },
  {
    columnId: "name",
    showingText: "Проект",
    style: { minWidth: "300px", maxWidth: "800px", marginLeft: "20px" },
  },
  {
    columnId: "rp",
    showingText: "РП",
    style: { minWidth: "90px", paddingLeft: "15px" },
  },
  {
    columnId: "team",
    showingText: "Команда",
    style: { minWidth: "90px" },
  },
  {
    columnId: "all_tasks",
    showingText: "Всего задач",
    style: { minWidth: "175px", paddingLeft: "20px" },
  },
  {
    columnId: "tasks_at_work",
    showingText: "В работе",
    style: { minWidth: "100px" },
  },
  {
    columnId: "tasks_at_end",
    showingText: "Завершено",
    style: { minWidth: "100px" },
  },
  {
    columnId: "tasks_at_overdue",
    showingText: "Просрочено",
    style: { minWidth: "100px" },
  },
];

const ProjectAllTableHead: React.FC<Props> = ({
  tableFilter,
  activeColumns,
  projectId,
  setTableFilter,
  changeTaskList,
  fetchTasks,
  setOrder,
  isScroll,
  setPageNum,
  isUpdateProject,
  setIsUpdateProject
}) => {

  const [timer, setTimer] =
    useState<ReturnType<typeof setTimeout> | null>(null);
  const [Clicked, setClicked] = useState<any>({ col: "id", count: 1 });
  const [activeSortIcon, setActiveSortIcon] = useState(); 

  const searchChangeHandler = useCallback(
    (filter: string) => {
      if (!projectId) return;
      setTableFilter(filter);
      if (timer) clearTimeout(timer);
      setTimer(
        setTimeout(() => {
          changeTaskList([]);
          fetchTasks(projectId, 1);
        }, 500)
      );
    },
    [changeTaskList, fetchTasks, projectId, setTableFilter, timer]
  );

  const firstUpdate = useRef(false);
  useLayoutEffect(() => {
    // при инициализации это не должно отрабатывать. для этого сделано условие с useRef
    if (firstUpdate.current) {
      setOrder(Clicked.col, Clicked.count === 1);
      setPageNum(1);
      localStorage.setItem(
        "cols_order_project",
        JSON.stringify({
          name: Clicked.col,
          order_direct: Clicked.count === 1,
        })
      );

      setActiveSortIcon({
        col: Clicked.col,
        count: Clicked.count
      });
    } 
    else {
      if(localStorage.getItem("cols_order_project")) {
        let parsed_obj = JSON.parse(
          localStorage.getItem("cols_order_project") as string
        );

        setActiveSortIcon({
          col: parsed_obj.name,
          count: parsed_obj.order_direct ? 1 : 2
        });
      }
      else {
        setActiveSortIcon({
          col: 'id',
          count: 2
        });
      }

      firstUpdate.current = true;
    }
  }, [Clicked]);

  useEffect(() => {
    let parsed_obj={name:"id",order_direct:1};

    if (isUpdateProject===true) {

      if (localStorage.getItem("cols_order_project")) {
        parsed_obj = JSON.parse(
          localStorage.getItem("cols_order_project") as string
        );

      } else {


      }

      setOrder(parsed_obj.name, parsed_obj.order_direct == 1);
      setPageNum(1);

  }

    setIsUpdateProject(false);

  }, [isUpdateProject]);

  return (
    <WrapperTasksTableHead isScroll={isScroll}>
      <BlockStyled>
        {Object.keys(columnsOfTables).map((key) => {
          return (
            (columnsOfTables[key].columnId === "id" ||
              columnsOfTables[key].columnId === "name") && (
              <HeaderItemName
                key={key}
                style={columnsOfTables[key].style}
                onClick={() => {
                  const count = (activeSortIcon.col === columnsOfTables[key].columnId)
                    ? activeSortIcon.count === 2
                      ? 1
                      : 2
                    : 1;
                  
                  setClicked({
                    col: columnsOfTables[key].columnId,
                    count
                  });

                  setActiveSortIcon({
                    col: columnsOfTables[key].columnId,
                    count
                  });
                }}
              >
                <span>
                  {columnsOfTables[key].showingText}
                  <SortIcon
                    active={activeSortIcon?.col === columnsOfTables[key].columnId}
                    activeSort={
                      activeSortIcon?.col === columnsOfTables[key].columnId &&
                      activeSortIcon?.count === 1
                        ? "top"
                        : "bottom"
                    }
                  />
                </span>

                {/* {Clicked.col === columnsOfTables[key].columnId &&
                    Clicked.count === 1 && <DetailsRoundedIcon />}
                  {Clicked.col === columnsOfTables[key].columnId &&
                    Clicked.count === 2 && <ChangeHistoryRoundedIcon />} */}
              </HeaderItemName>
            )
          );
        })}
      </BlockStyled>

      <BlockStyled style={{ marginRight: "45px" }}>
        {Object.keys(columnsOfTables).map((key) => {
          return columnsOfTables[key].columnId === "rp" ||
            columnsOfTables[key].columnId === "team" ||
            columnsOfTables[key].columnId === "all_tasks" ||
            columnsOfTables[key].columnId === "tasks_at_work" ||
            columnsOfTables[key].columnId === "task_load" ||
            columnsOfTables[key].columnId === "tasks_at_end" ||
            columnsOfTables[key].columnId === "tasks_at_overdue" ? (
            <HeaderItemName
              key={columnsOfTables[key].columnId}
              style={columnsOfTables[key].style}
              // onClick={() => {
              //   setClicked({
              //     col: columnsOfTables[key].columnId,
              //     count:
              //       Clicked.col === columnsOfTables[key].columnId
              //         ? Clicked.count === 2
              //           ? 1
              //           : Clicked.count + 1
              //         : 1,
              //   });
              // }}
            >
              <span>
                {columnsOfTables[key].showingText}
                {/* <SortIcon
                  active={Clicked.col === columnsOfTables[key].columnId}
                  activeSort={
                    Clicked.col === columnsOfTables[key].columnId &&
                    Clicked.count === 1
                      ? "top"
                      : "bottom"
                  }
                /> */}
              </span>

              {/*{Clicked.col === columnsOfTables[key].columnId && Clicked.count === 1 &&*/}
              {/*<Icon name={'sort down'} style={{marginLeft: '8px'}}/>*/}
              {/*}*/}
              {/*{Clicked.col === columnsOfTables[key].columnId && Clicked.count === 2 &&*/}
              {/*<Icon name={'sort up'} style={{marginLeft: '8px'}}/>}*/}
            </HeaderItemName>
          ) : null;
        })}
      </BlockStyled>
    </WrapperTasksTableHead>
  );
};

const mapStateToProps = (state: State) => {
  return {
    activeColumns: state.projectsPage.activeColumns,
    tableFilter: state.projectsPage.tableFilter,
    projectId: state.projectsPage.selectedProject?.id || null,
    isUpdateProject: state.newProject.isUpdateProject,
  };
};

const mapDispatchToProps = {
  setTableFilter,
  changeTaskList,
  fetchTasks,
  setOrder,
  setIsUpdateProject
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectAllTableHead);
