// Для выбора
import React, { useState, useRef, useEffect } from "react";
import { Item } from "../../types";
import Chips from "../ChipsItem";
import styled from "styled-components";
import CheckboxPeople from "../CheckboxItem/CheckboxPeopel";
import SearchInput from "../newComponent/Inputs/SearchInput";
import { nanoid } from "nanoid";

type Props = {
  changeProject?: boolean; //******************************** */
  selectedItems: Item[];
  itemsList: Item[];
  disabled?: boolean;
  placeholder?: string;
  radio?: boolean; // возможность выбрать 1 элемент (true) или несколько(false)
  isValid?: boolean;
  addHandler: (value: number) => void;
  removeHandler: (value: number) => void;
  showSearchField?: boolean;
  classname?: string;
  textFieldValue?: string;
  attached_items?: Item[];
  current_value?: string;
  length_block?: number;
  withImage?: boolean;
  chipsWidth?: number;
  selectedItemImage?: string;
  showAvatar?: boolean;
  IconName?: string;
  resetInput?: boolean
};
const WrapperMultySelect = styled.div`
  position: relative;
`;
const WrapperChipsBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.25rem;
`;

const MultySelect: React.FC<Props> = ({
  changeProject,
  selectedItems,
  itemsList,
  placeholder,
  isValid = true,
  disabled = false,
  radio = false,
  addHandler,
  removeHandler,
  showSearchField = true,
  classname,
  textFieldValue,
  attached_items,
  current_value,
  length_block = "100%",
  withImage,
  chipsWidth,
  selectedItemImage,
  showAvatar,
  IconName,
  resetInput
}) => {
  const [show, setShow] = useState<boolean>(false);
  const list = useRef<HTMLDivElement>(null);
  const [filterItemsList, setFilterItemsList] = useState([...itemsList]);
  const [value, setValue] = useState("");

  const search = (event) => {
    setShow(true);
    setValue("");

    setFilterItemsList([...itemsList]);
    let searchString = event.target.value.toLowerCase();
    setValue(searchString);

    let filter = itemsList.filter((data) => {
      if (data.rus_name) {
        // приоритет - смотреть по параметру rus_name
        return data.rus_name?.toLowerCase().includes(searchString);
      } else {
        return data.text?.toLowerCase().includes(searchString);
      }
    });

    if (searchString.length === 0) setFilterItemsList([...itemsList]);
    else setFilterItemsList(filter);
  };

  const outsideClick = (e: Event) => {
    if (show && !list.current?.contains(e.target as HTMLElement)) {
      setShow(false);
      setValue(current_value as string);
    }
  };
  
  const resetInputFn = () => {
    setValue('');
    setFilterItemsList([...itemsList]);
    setShow(false);
  };

  useEffect(() => {
    if(!show) {
      resetInputFn();
    }
  }, [show]);

  useEffect(() => {
    if(resetInput) {
      resetInputFn();
    }
  }, [resetInput]);
  
  useEffect(() => {
    if (current_value) setValue(current_value as string);
  }, [current_value, changeProject]); //************************************** */

  useEffect(() => {
    if (textFieldValue !== undefined) setValue(textFieldValue as string);
  }, [textFieldValue]);

  useEffect(() => {
    document.addEventListener("click", outsideClick);
    return () => document.removeEventListener("click", outsideClick);
  }, [outsideClick]);

  useEffect(() => {
    if (radio && selectedItems.length) {
      setFilterItemsList([...itemsList]);
      setShow(false);
    }
  }, [selectedItems.length, radio, itemsList]);

  return (
    <WrapperMultySelect
      className='multi-select'
      ref={list}
      style={{
        width:
          typeof length_block === "number" ? length_block + "px" : length_block,}}
    >
      {/* Форма для выбора */}
      {showSearchField &&
        (!radio ? ( // мультипл выбор
          <SearchInput
            fontSize="14px"
            value={value}
            classname={classname + ' multi-select__search-input'}
            disabled={disabled}
            placeholder={placeholder}
            onChange={search}
            onClick={() => {
              setShow(!show);
            }}
          />
        ) : radio && selectedItems.length === 0 ? ( // сингл выбор
          <SearchInput
            fontSize="14px"
            single_choice={radio && !show}
            value={value}
            selectedItemImage={selectedItemImage}
            showAvatar={showAvatar}
            IconName={IconName}
            classname={classname + ' multi-select__search-input'}
            disabled={disabled}
            placeholder={placeholder}
            onChange={search}
            onClick={() => {
              if (show) setValue(current_value as string);
              else setValue("");
              setFilterItemsList([...itemsList]);
              setShow(!show);
            }}
          />
        ) : null)}

      <WrapperChipsBlock>
        {selectedItems.map((item) => {
          return (
            <Chips
              chipsWidth={(length_block as number) - 42}
              disabled={disabled}
              text={item?.rus_name ? item.rus_name : item.text}
              key={nanoid()}
              image={item.urlImage}
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                removeHandler(item.value as number);
              }}
            />
          );
        })}
      </WrapperChipsBlock>

      {show && (
        <CheckboxPeople
          length_block={length_block as number}
          users={filterItemsList}
          selectedUsers={selectedItems}
          attached_items={attached_items}
          addHandler={(arg) => {
            if (radio) setShow(false); // закрывать выпадающий список при клике по пункту, если это сингл выбор
            addHandler(arg);
          }}
          removeHandler={removeHandler}
          radio={radio}
          withImage={withImage}
        />
      )}
    </WrapperMultySelect>
  );
};

export default MultySelect;
