import React from "react";

interface Props {
  width?: number|string;
}

const Refusal:React.FC<Props> = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="35"
        height="35"
        rx="3.5"
        fill="#F44336"
        fill-opacity="0.1"
        stroke="#F44336"
      />
      <path
        d="M21.2363 15.0469L24.1895 18L21.2363 20.9531"
        stroke="#F44336"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.3125 18L24.1875 18"
        stroke="#F44336"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.4375 24.75L12.375 24.75C12.2258 24.75 12.0827 24.6907 11.9773 24.5852C11.8718 24.4798 11.8125 24.3367 11.8125 24.1875L11.8125 11.8125C11.8125 11.6633 11.8718 11.5202 11.9773 11.4148C12.0827 11.3093 12.2258 11.25 12.375 11.25L17.4375 11.25"
        stroke="#F44336"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Refusal;
