import React from 'react';
import './PreloaderLocal.scss';

const PreloaderLocal = () => {
  return (
    <div className="preloader-local">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
};

export default PreloaderLocal;
