import { Project, User, Status, Role, Position, Department, Workgroup, Section } from "./types"
import { State } from "../rootReducer"

export const getProjects = (state: State): Project[] => state.commonInfo.projectsTemp
export const getUsers = (state: State): User[] => state.commonInfo.users
export const getStatuses = (state: State): Status[] => state.commonInfo.statuses
export const getRoles = (state: State): Role[] => state.commonInfo.roles
export const getPositions = (state: State): Position[] => state.commonInfo.positions
export const getDeps = (state: State): Department[] => state.commonInfo.departments
export const getGroups = (state: State): Workgroup[] => state.commonInfo.workgroups
export const getSections = (state: State): Section[] => state.commonInfo.sections
export const getCurrentUserInfo = (state: State): User | null => state.commonInfo.currentUserInfo