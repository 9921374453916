import { status } from "./../TaskInfoPlate/components/InfoTab";
import { takeEvery, call, put } from "redux-saga/effects";
import {
  types,
  createUserAciton,
  changePass,
  deleteUserAction,
  addPositionAction,
} from "./action_types";
import { store } from "../../App";
import { State } from "../../rootReducer";
import { fetchData } from "../../utils/fetchData";
import { DayOff } from "../../common/types";
import { CreateNotif } from "../../utils/createNotification";
import {
  fetchUsers,
  putFetchedUsersByPages,
  setIsUserDelete,
  setUserAddMode,
} from "../Users/actions";
import {
  addPositionInCompany,
  ChangeIndicator,
  clearUser, 
  createDefaultUserNotification, 
  setCreatedUserNotification,
  setIsLoad,
  setIsSending,
  setPositionAddMode,
  setSelectAddedPosition,
  setUserDeleteInfo,
  setUserPosition,
} from "./actions";
import {
  setUsers,
  setCurrentUserInfo,
  setPositions,
} from "../../common/actions";
import { cookieMaster } from "../../utils/CookieMaster";
import {
  deleteCommentFileAction,
  uploadCommentFileAction,
} from "../TaskInfoPlate/action_types";
import { setCommentFiles } from "../TaskInfoPlate/actions";
import { NewUserState } from "./reducer";
import { timeZone } from "../../common/format";

function checkUpdateData(body, id, curentUserId) {
  //удаляем из запроса недопустимые поля

  delete body["roles"]; //сами себе мы не можем устанавливать роль
  delete body["positions"]; //сами себе мы не можем устанавливать должность
  delete body["departments"]; //сами себе мы не можем устанавливать отдел
  delete body["email"]; //сами себе мы не можем менять емаил

  return body;
}

function* createUser({
  update,
  changeDataUser,
  notificationOptions,
  notificationSuccess,
  isUserDelete
}: createUserAciton) {
  yield put(setIsSending(true));
  let {
    commonInfo: { users },
    newUser,
  }: State = store.getState();
  const id = newUser.id;
  let curentUserId = store.getState().commonInfo.current_user;
  let body = {
    email: newUser.email,
    // password: newUser.password,
    surname: newUser.surname,
    name: newUser.name,
    roles: newUser.roles,
  };
  if (newUser.password) body["password"] = newUser.password;
  if (newUser.departments.length > 0) body["departments"] = newUser.departments;
  if (newUser.patronymic) {
    body["patronymic"] = newUser.patronymic;
  } else {
    delete body["patronymic"];
  }
  if (newUser.positions.length > 0) body["positions"] = newUser.positions;
  if (newUser.birthday) body["birthday"] = newUser.birthday;

  let schedule = {
    weekDays: [
      ...newUser.schedule.map((day: number) => ({
        day,
        time: [{ begin: newUser.workBegin, end: newUser.workEnd }],
      })),
    ],
  };
  if (newUser.daysOff.length > 0) {
    schedule["daysOff"] = [
      ...newUser.daysOff.map((dayOff: DayOff) => ({
        date: dayOff.date, //отдаем в формате DD.MM.YYYY
        //date: (new Date(dayOff.date)).toLocaleDateString(),
        comment: dayOff.comment,
      })),
    ];
  }

  if (newUser.image instanceof File) {
    let formData = new FormData();
    formData.append("file", newUser.image);
    const image = yield call(fetchData.post, "/api/v1/files", formData, {
      Authorization: cookieMaster.getCookie("access_token"),
    });
    if (image) {
      body["image_file_id"] = image.id;
      if (changeDataUser?.data) {
        // если пришло как "обновление"
        changeDataUser.data["image_file_id"] = image.id;
      }
    }
  }
  let fetchedUserData;
  let fetchedUserSchedule;
  let fetchUserNotificationAuthor;
  let fetchUserNotificationExecutor;

  let company = localStorage.getItem("company_id");
  /*небольшой костыль, это по хорошему вынести на уровень компонентов*/

  if (update) {
    /*костыль, это по хорошему вынести на уровень компонентов*/
    // body = checkUpdateData(body, id, curentUserId);
    /*костыль, это по хорошему вынести на уровень компонентов*/
    if (Object.keys(changeDataUser.data).length) {
      fetchedUserData = yield call(
        fetchData.patch,
        `/api/v1/users/${id}`,
        JSON.stringify(changeDataUser.data)
      );
    }

    if (
      //  changeDataUser.schedule.daysOff.length ||
      changeDataUser.schedule.weekDays.length
    ) {
      let schedule = {};
      //  if (changeDataUser.schedule.daysOff.length) {
      schedule["daysOff"] = changeDataUser.schedule.daysOff;
      // schedule['weekDays'] = changeDataUser.schedule.weekDays;
      //  }
      if (changeDataUser.schedule.weekDays.length) {
        schedule["weekDays"] = changeDataUser.schedule.weekDays;
      }

      let time_Zone = timeZone();

      fetchedUserSchedule = yield call(
        fetchData.patch,
        `/api/v1/users/${id}/schedule?tz=${time_Zone}`,
        JSON.stringify({ schedule: schedule })
      );

      // CreateNotif('Данные пользователя успешно обновлены', 'success');
      // yield put(clearUser());
    }
    if (notificationSuccess && !!notificationOptions) {
      fetchUserNotificationAuthor = yield call(
        fetchData.patch,
        `/api/v1/notification-settings/${notificationOptions[0].id}`,
        JSON.stringify({
          settings: Object.values(notificationOptions[0].settings),
        })
      );

      fetchUserNotificationExecutor = yield call(
        fetchData.patch,
        `/api/v1/notification-settings/${notificationOptions[1].id}`,
        JSON.stringify({
          settings: Object.values(notificationOptions[1].settings),
        })
      );
    }

    if (
      fetchedUserData ||
      fetchedUserSchedule ||
      fetchUserNotificationAuthor ||
      fetchUserNotificationExecutor
    )
      yield put(fetchUsers(1));
      yield put(setIsUserDelete(!isUserDelete));
    CreateNotif("Данные пользователя успешно обновлены", "success");

    // yield put(clearUser());
  } else {
    body["password"] = null;

    fetchedUserData = yield call(
      fetchData.post,
      `/api/v1/users`,
      JSON.stringify(body)
    );
    if (fetchedUserData) {
      // CreateNotif("Если в течение десяти минут электронное письмо не получено, проверьте правильность указанного адреса", "success");
      CreateNotif(
        "Мы отправили новому сотруднику логин и пароль на электронную почту, которую вы указали!",
        "success"
      );

      yield put(clearUser());
    }
    // if (fetchedUserData) {
    //   let data = yield call(
    //     fetchData.post,
    //     `/api/v1/users/${fetchedUserData.id}/schedule`,
    //     JSON.stringify({ schedule })
    //   );

    // }
  }

  if (fetchedUserData) {
    yield put(
      setUsers([
        ...users.filter((user) => user.id !== id),
        {
          ...fetchedUserData,
          departments: newUser.departments,
          // positions: newUser.positions,
          // roles: newUser.roles
        },
      ])
    );
    if (fetchedUserData.id === curentUserId)
      yield put(setCurrentUserInfo(fetchedUserData));

    // запускает триггер, редиректящий на дефолтный путь /users
    yield put(ChangeIndicator());
  }

  yield put(setIsSending(false));
}

function* addPosition({ companyId, name }: addPositionAction) {
  yield put(setIsLoad(true));
  const position = yield call(
    fetchData.post,
    `/api/v1/positions`,
    JSON.stringify({ company_id: companyId, name })
  );
  if (position) {
    yield put(setIsLoad(false));
    let positions = store.getState().commonInfo.positions;
    yield put(setPositions([...positions, position]));
    CreateNotif("Изменения успешно сохранены", "success");
    yield put(setPositionAddMode(false));
    yield put(setSelectAddedPosition(position.id));
  }
}

function* changePassword({ oldPassword, password }: changePass) {
  const dep = yield call(
    fetchData.post,
    "/api/v1/password/simple-change",
    JSON.stringify({
      oldPassword: oldPassword,
      password: password,
      password_confirmation: password,
    })
  );
  if (dep?.success) CreateNotif("Пароль успешно изменен", "success");
}

function* resetPass() {
  const email = store.getState().commonInfo.currentUserInfo?.email;
  const dep = yield call(
    fetchData.post,
    "/api/v1/password/reset",
    JSON.stringify({
      email,
    })
  );

  if (dep.message === "We have e-mailed your password reset link!") {
    CreateNotif("Ссылка для сброса пароля отправлена вам на почту", "success");
  }
}

function* deleteUser({ companyId, userId, isUserDelete}: deleteUserAction) {
  try {
    const deleteResponse = yield call(
      fetchData.delete,
      `/api/v1/companies/${companyId}/users/${userId}`
    );
    if (deleteResponse.success) {
      const currentUsers = store.getState().commonInfo.users;
      const users = currentUsers.filter((user) => user.id !== userId);
      yield put(setUsers(users));
      yield put(setIsUserDelete(!isUserDelete));
    }
    yield put(setUserDeleteInfo(null));
  } catch {
    yield put(setUserDeleteInfo(null));
  }
}

function* createUserDefaultNotification( {idUser, data }: any) {
  let response: any = [];

  if(data.length) {
    for(let notifData of data) {
      const responseLocal = yield call(
        fetchData.post,
        '/api/v1/notification-settings',
        JSON.stringify({
          user_id: idUser,
          role: notifData.role,
          settings: Object.values(notifData.settings)
        })
      );
      
      if(responseLocal) {
        response.push(responseLocal);
      }
    }
  }

  if(response.length) {
    yield put(setCreatedUserNotification(response));
  }
}

export function* watchPasswordReset() {
  yield takeEvery(types.PASS_RESET, resetPass);
}

export function* watchPasswordChange() {
  yield takeEvery(types.CHANGE_PASS, changePassword);
}

export function* watchCreateUser() {
  yield takeEvery(types.CREATE_USER, createUser);
}
export function* watchAddUserPosition() {
  yield takeEvery(types.ADD_POSITION, addPosition);
}
export function* watchDeleteUser() {
  yield takeEvery(types.DELETE_USER, deleteUser);
}

export function* watchCreateUserDefaultNotification() {
  yield takeEvery(types.CREATE_USER_DEFAULT_NOTIFICATION, createUserDefaultNotification);
}
