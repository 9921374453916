import React from "react";

const Agreed:React.FC = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="1.12109"
        width="35"
        height="35"
        rx="3.5"
        fill="#43A047"
        fillOpacity="0.1"
        stroke="#43A047"
      />
      <path
        d="M24.375 14.6211L16.125 22.4961L12 18.5586"
        stroke="#43A047"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Agreed;
