import React, { useEffect, useState } from "react";
import MenuHead from "../Menu/MenuHead";
import SchedulerDate from "./SchedulerDate";
import SchedulerViewSwitcher from "./SchedulerView";
import UsersSelect from "./UsersSelect";
import { CreateNotif } from "../../../../utils/createNotification";
import { useSelector } from "react-redux";
import { State } from "../../../../rootReducer";

interface Props {
  selectedDate: Date;
  setSelectedDate: (date: Date) => void;
  view:"day" | "week" ;
  setView:(mode:"day" | "week" )=>void
  setIsTaskView: (mode: boolean) => void;
  setIsShowAddObject: (mode: boolean) => void;
  setCurrentUser: (id: number|string)=>void
}

const scheduler = window.scheduler;

const Header = ({ selectedDate, setSelectedDate, view, setView,setIsTaskView,setIsShowAddObject, setCurrentUser }) => {

  // дата регистрации пользователя для вычисления диапазона дат календаря
  const registrationDate = 
    useSelector((state: State) => state?.commonInfo?.currentUserInfo?.departments_model[0]?.created_at)
  // нужно на беке добавить в объект личную дату регистрации авторизованного пользователя и type
  const minDate = registrationDate ? new Date(registrationDate) : null;

  const [date, setDate] = useState<Date>(new Date());

  const handleViewChange = (newView: "day" | "week") => {
    setView(newView);
    scheduler.setCurrentView(selectedDate, newView);
  };

  const isDateWithinLimits = (newDate: Date) => {
    const today = new Date();
    const maxDate = new Date(today.getFullYear() + 1, 11, 31);

    if (newDate > maxDate) {
      CreateNotif('Достигнут максимальный диапазон даты от сегодняшнего дня')
      return false;
    }

    if (minDate && newDate < minDate) {
      CreateNotif('нельзя выбрать дату раньше даты регистрации')
      return false;
    }

    return true
  }

  const dayPrevClick = () => {
    const newDate = scheduler.date.add(selectedDate, -1, "day");
    if (isDateWithinLimits(newDate)) {
      setSelectedDate(newDate);
      scheduler.setCurrentView(newDate, view);
    }
  };

  const dayNextClick = () => {
    const newDate = scheduler.date.add(selectedDate, 1, "day");
    if (isDateWithinLimits(newDate)) {
      setSelectedDate(newDate);
      scheduler.setCurrentView(newDate, view);
    }
  };

  const handleWeekPrevClick = () => {
    const newDate = scheduler.date.add(selectedDate, -7, "day");
    if (isDateWithinLimits(newDate)) {
      setSelectedDate(newDate);
      scheduler.setCurrentView(newDate, view);
    }
  };

  const handleWeekNextClick = () => {
    const newDate = scheduler.date.add(selectedDate, 7, "day");
    if (isDateWithinLimits(newDate)) {
      setSelectedDate(newDate);
      scheduler.setCurrentView(newDate, view);
    }
  };
 

  return (
    <div className="header">
      <SchedulerViewSwitcher view={view} onViewChange={handleViewChange} />
      <SchedulerDate
        date={selectedDate}
        view={view}
        dayPrevClick={dayPrevClick}
        dayNextClick={dayNextClick}
        weekPrevClick={handleWeekPrevClick}
        weekNextClick={handleWeekNextClick}
       
      />
      <div className="users_select-container">
      <div className="users_select-title">Для сотрудника</div>
      <UsersSelect setCurrentUser={setCurrentUser} />
      </div>
      <>
        <MenuHead setIsTaskView={setIsTaskView}
        setIsShowAddObject={setIsShowAddObject}/>
      </>
    </div>
  );
};

export default Header;
