import React, {
  FC,
  useRef,
  useEffect,
  useState,
  DragEvent,
  useLayoutEffect,
} from "react";
import "dhtmlx-scheduler";
import "dhtmlx-scheduler/codebase/dhtmlxscheduler_material.css";
import "./style.sass";
import { defaultScrollHour, initConfig, initSchedulerEvents } from "../config";
import Header from "./SchedulerHeader/Header";
import { fetchData } from "../../../utils/fetchData";
import { CreateNotif } from "../../../utils/createNotification";
import { useParams } from "react-router-dom";
import moment from "moment";
import Menu from "./Menu/Menu";
import {
  AVERAGE_PRIORITY,
  COMPLETED_STATUS,
  HIGH_PRIORITY,
  MADE_STATUS,
  OVERDUE_STATUS,
  URGENT_PRIORITY,
  convertDateToUTC,
  calculateDateIntervalsForView,
  compareTime,
  roundUpMinutes,
  getRandomInt,
  IN_WORK_STATUS,
  SHORT_PRIORITY,
} from "../helpers";
import axios from "axios";
import { State } from "../../../rootReducer";
import { selectTask, setCellTaskData } from "../../TaskInfoPlate/actions";
import { Task } from "redux-saga";
import { cookieMaster } from "../../../utils/CookieMaster";
import { connect, useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setTaskPriority } from "../../TaskInfoPlate/actions";
import { store } from "../../../App";

interface ICalendar {
  events: any;
  refA: React.RefObject<HTMLDivElement>;
  selectedDate: Date;
  setSelectedDate: (date: Date) => void;
  view: "day" | "week";
  setView: (mode: "day" | "week") => void;
  setIsTaskView: (mode: boolean) => void;
  setIsShowAddObject: (mode: boolean) => void;
  setCellData: (mode: any) => void;
  addEventHandler: (event: any, ans: any) => void;
  currentStatus: (stat: number, date: Date) => void;
  statuses: any;
  setCurrentUser: (id: number | string) => void;
  selectTask: (task: Task) => void;
  setCellTaskData: (data: any) => void;
  data: any;
  highlightedItem: any
}
let REACT_APP_PUBLIC_API = `${process.env.REACT_APP_PUBLIC_API}`;

const scheduler = window.scheduler;

const Scheduler: FC<ICalendar> = ({
  events,
  refA,
  selectedDate,
  setSelectedDate,
  view,
  setView,
  setIsTaskView,
  setIsShowAddObject,
  setCellData,
  addEventHandler,
  currentStatus,
  statuses,
  setCurrentUser,
  selectTask,
  setCellTaskData,
  data,
  highlightedItem
}) => {
  const [isInit, setIsInit] = useState(false);
  const [filter, setFilter] = useState<any>();
  const [firstPaintScrolled, setFirstPaintScrolled] = useState(false);
  let token = cookieMaster.getCookie("access_token");
  const [isRefInited, setIsRefInited] = useState<boolean>(false);
  const dispatch = useDispatch();

  const userId = localStorage.getItem("user_id");
  const params = useParams<{ id: string }>();
  let priorities = useSelector((state: State) => state.commonInfo.priorities);

  const priorityColor = (priority) => {
    let priorityStatus = priorities.find((el) => el.id === priority);
    
    let color = "";
    if(priorityStatus)
     if (priorityStatus.slug === AVERAGE_PRIORITY) {
       color = "average_priority";
     } else if (priorityStatus.slug === HIGH_PRIORITY) {
      color = "high_priority";
     } else if (priorityStatus.slug === URGENT_PRIORITY) {
       color = "urgent_priority";
     } else if (priorityStatus.slug === SHORT_PRIORITY) {
       color = "in_work_priority";
     }

    return color;
  };
  const scrollToFirstEvent = () => {
    const dateIntervals = calculateDateIntervalsForView(selectedDate, view);
    const events = scheduler.getEvents(
      dateIntervals.start_date,
      dateIntervals.end_date
    );
    if (events.length > 0) {
      const firstEventInView = events.reduce((minDate, currentDate) => {
        return compareTime(minDate.start_date, currentDate.start_date) > 0
          ? minDate
          : currentDate;
      });
      scheduler.showEvent(firstEventInView.id);
      return firstEventInView;
    } else {
      const currentDate = scheduler.getState().date;
      const targetDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate(),
        defaultScrollHour
      );
      // Такая же реализация в scheduler.showEvent()
      scheduler.config.preserve_scroll = false;
      scheduler.setCurrentView(targetDate);
      scheduler.config.preserve_scroll = true;
    }
    return null;
  };

  useEffect(() => {
    const data = initSchedulerEvents(scheduler, params.id);
    setIsInit(true);
    return () => {
      data.events.forEach((event) => {
        scheduler.detachEvent(event);
      });
    };
  }, []);

  useEffect(() => {
    initConfig(scheduler);

    //@ts-ignore
    window.data = events;

  }, [events]);

  useEffect(() => {
    if (refA.current) {
      if (!isRefInited) {
        scheduler.init(refA.current, new Date());
        setIsRefInited(true);
      }
      scheduler.parse(events);
      scheduler.templates.tooltip_text = function (start, end, event) {
        return "<b>" + event.text + "</b>";
      };

      scheduler.templates.event_class = function (start, end, event) {
        let css: any = "just_event";
        if (event.entity) {
          if (!event.entity?.hasOwnProperty("type")) {
            return css;
          }
          if (event.entity.type == "MEETING_TYPE") {
            css = "meeting-event"; // задаем класс для событий типа "meeting"

            const isMember = event.meta?.members?.map(member => member.user_id).includes(+(userId ?? 0));

            if(isMember && (
              event.meta?.initiator_id !== Number(userId) &&
              event.meta?.chairman?.user_id !== Number(userId) &&
              event.meta?.secretary?.user_id !== Number(userId))
            ) {
              css += " disabled_delete_icon";
            }

            if (
              event.meta?.initiator_id !== Number(userId) &&
              event.meta?.chairman?.user_id !== Number(userId) &&
              event.meta?.secretary?.user_id !== Number(userId) &&
              !isMember
            ) {
              css += " disabled_event";
            }
          } else if (event.entity.type == "TASK_TYPE") {
            const status: any = currentStatus(
              event.meta?.task_status_id
                ? event.meta?.task_status_id
                : IN_WORK_STATUS,
              event.end_date
            );
            if (status?.id === OVERDUE_STATUS) {
              css = `task_overdue ${priorityColor(
                event?.meta?.task_priority_id
              )}`;
            } else if (status?.id === COMPLETED_STATUS) {
              css = `task_comp ${priorityColor(event?.meta?.task_priority_id)}`;
            } else if (status?.id === MADE_STATUS) {
              css = `task_completed ${priorityColor(
                event?.meta?.task_priority_id
              )}`;
            } else {
              css = `task-event ${priorityColor(
                event?.meta?.task_priority_id
              )}`;
            }
            if (userId !== params.id) {
              css += " disabled_event";
            }
          } else if (event.entity.type == "EVENT_TYPE") {
            css = ""; // задаем класс для событий типа "event"
            if (userId !== params.id) {
              css += " disabled_event";
            }
          } else if (event.entity.type == "EVENT_TASK_TYPE") {
            const status: any = currentStatus(
              event.meta?.task_status_id
                ? event.meta?.task_status_id
                : IN_WORK_STATUS,
              event.end_date
            );
            if (status?.id === OVERDUE_STATUS) {
              css = `task_overdue ${priorityColor(
                event?.meta?.task_priority_id
              )}`;
            } else if (status?.id === COMPLETED_STATUS) {
              css = `task_comp ${priorityColor(event?.meta?.task_priority_id)}`;
            } else if (status?.id === MADE_STATUS) {
              css = `task_completed ${priorityColor(
                event?.meta?.task_priority_id
              )}`;
            } else {
              css = `task-event ${priorityColor(
                event?.meta?.task_priority_id
              )}`;
            }
            if (userId !== params.id) {
              css += " disabled_event";
            }
          }
          css = `${css} event-type__${event.entity.type}`;
          if (event.end_date < new Date()) {
            css = `${css} overdue-object`;
          }
        }
        if (event.hasOwnProperty("isLoading") && event.isLoading) {
          css = `${css} event-loader__active`;
        }
        if(highlightedItem === event?.meta?.task_id){
          css = `${css} highlighted` 
        }
        css = `${css} minute-diff__${
          (end.getTime() - start.getTime()) / 60000
        }`;
        return css;
      };
      scheduler.templates.event_text = function (start, end, event) {
        let text = `<span>${event.text}</span>`;
        if (
          event.entity &&
          event.entity?.hasOwnProperty("type") &&
          event.entity.type == "TASK_TYPE"
        ) {
          const imgTag = `<img style="position: absolute; left:18px; top: 4px; width: 12px; height: 12px;vertical-align: baseline;margin-left: -3px;pointer-events: none" src='/assets/day/fire.png' alt="fire"/>`;
          text = `${imgTag} <span class="deadline">${event.text}</span>`;
        }

        return text;
      };

      scheduler.render();
      // scheduler.updateView(); - вернуть если ячейки станут плохо рендериться
    }
  }, [isInit, statuses, events, filter, highlightedItem]);
  useEffect(() => {
    if (isInit) {
      scheduler.updateView(selectedDate);
      scheduler.render();
    }
  }, [selectedDate]);

  useEffect(() => {
    scheduler.clearAll(); // очищаем события в scheduler
    scheduler.parse(
      events.filter((item) => {
        if (
          (item.entity.type == "EVENT_TASK_TYPE" ||
            item.entity.type == "TASK_TYPE") &&
          filter.tasks == true
        ) {
          if (item.meta.is_cyclic == true && filter.hideEveryDay == true) {
            return false;
          }
          if (item.meta.is_done == true && filter.hideDone == true) {
            return false;
          }
          if (item.meta.is_completed == true && filter.hideCompleted == true) {
            return false;
          }
          return item;
        } else if (item.entity.type == "TASK_TYPE" && filter.tasks == true) {
          return item;
        } else if (item.entity.type == "EVENT_TYPE" && filter.events == true) {
          return item;
        } else if (
          item.entity.type == "MEETING_TYPE" &&
          filter.meetings == true
        ) {
          return item;
        }
      })
    ); // загружаем обновленные события
  }, [filter]);
  //Для первого рендера
  useEffect(() => {
    if (events.length > 0 && !firstPaintScrolled) {
      scrollToFirstEvent();
      setFirstPaintScrolled(true);
    }
  }, [events]);
  //Для последующих рендеров, чтобы небыло дерганий
  useLayoutEffect(() => {
    if (firstPaintScrolled) {
      scrollToFirstEvent();
    }
  }, [selectedDate]);

  // добавление события по двойному клику
  useEffect(() => {
    const attEven = scheduler.attachEvent(
      "onEmptyClick",
      (id, e) => {
        if (e.detail === 2) {
          e.preventDefault();
          let priorities= store.getState().commonInfo.priorities;
          let priority = priorities.find((el) => el.slug === "medium") || priorities[1];
          if (priority) dispatch(setTaskPriority(priority?.id)); 

          const actionData = scheduler.getActionData(e); // получаем данные о начале и конце ячейки
          const start = actionData.date;
          const duration = 60; // продолжительность события в минутах
          const end = scheduler.date.add(start, duration, "minute");

          const newEventId = scheduler.addEvent({
            start_date: new Date(start),
            end_date: new Date(end),
            text: "",
          });
          const newEvent = scheduler.getEvent(newEventId);

          setCellData({
            start_at: start,
            end_at: end,
            newEvent,
            isChange: false,
          });
          setIsShowAddObject(true);
        }
      },
      { settings: true }
    );
    return () => scheduler.detachEvent(attEven);
  }, []);

  useEffect(() => {
    (scheduler as any)._click.buttons.delete = function (id) {
      var modalBox = scheduler.modalbox({
        title: "Удаление события",
        text: "Вы уверены, что хотите удалить это событие?",
        buttons: [
          {
            label: "Удалить",
            css: "btn-confirm",
          },
          {
            label: "Отмена",
            css: "btn-cancel",
          },
        ],
        callback: function (result) {
          switch (result) {
            case "0":
              scheduler.deleteEvent(id);
              break;
          }
        },
      });
    };
  }, []);

  const updateObject = (obj) => {
    const updatedObject = { ...obj };

    // изменяем ключи начала и конца задачи в нужный формат в новом объекте
    updatedObject.begin = moment(updatedObject.begin).format(
      "DD-MM-YYYY HH:mm:ss"
    );
    updatedObject.end = moment(updatedObject.end).format("DD-MM-YYYY HH:mm:ss");

    // возвращаем обновленный объект
    return updatedObject;
  };

  useEffect(() => {
    (scheduler as any)._click.buttons.edit = function (id) {
      const currentEvent = scheduler.getEvent(id);
      if (currentEvent.entity.type == "EVENT_TYPE") {
        setCellData({
          title: currentEvent.text,
          description: currentEvent.meta.entityData.description,
          color: currentEvent.meta.entityData.color,
          start_at: currentEvent?.start_date,
          end_at: currentEvent?.end_date,
          event_id: id,
          type: "EVENT_TYPE",
          isChange: true,
        });
        setIsShowAddObject(true);
      }
      if (currentEvent.entity.type == "MEETING_TYPE") {
        setCellData({
          title: currentEvent.text,
          start_at: currentEvent?.start_date,
          end_at: currentEvent?.end_date,
          event_id: id,
          meeting_id: currentEvent.entity.id,
          type: "MEETING_TYPE",
          isChange: true,
        });
        setIsShowAddObject(true);
      }
      if (currentEvent.entity.type == "EVENT_TASK_TYPE") {
        const deadlineEventId = data.find((item) => {
          if (
            item.meta.task_id == currentEvent.meta.task_id &&
            item.entity.type == "TASK_TYPE"
          ) {
            return item;
          }
        });
        setCellTaskData({
          // id: currentEvent?.id,
          title: currentEvent.text,
          start_at: currentEvent?.start_date,
          end_at: currentEvent?.end_date,
          event_id: id,
          task_id: currentEvent.meta.task_id,
          type: currentEvent.entity.type,
          isChange: true,
          scheduler,
          deadlineEventId: deadlineEventId?.id,
        });
        const TaskId = currentEvent.meta.task_id;
        axios
          .get(`${REACT_APP_PUBLIC_API}/tasks/${TaskId}`, {
            headers: {
              Authorization: token,
            },
          })
          .then((res) => {
            selectTask(updateObject(res.data.data));
          })
          .catch((err) => {
            console.log(err);
          });
      }
      if (currentEvent.entity.type == "TASK_TYPE") {
        const deadlineEventId = data.find((item) => {
          if (
            item.meta.task_id == currentEvent.meta.task_id &&
            item.entity.type == "EVENT_TASK_TYPE"
          ) {
            return item;
          }
        });
        setCellTaskData({
          // id: currentEvent?.id,
          title: currentEvent?.text,
          start_at: currentEvent?.start_date,
          end_at: currentEvent?.end_date,
          event_id: id,
          task_id: currentEvent?.meta.task_id,
          type: currentEvent?.entity.type,
          isChange: true,
          scheduler,
          taskEventId: deadlineEventId?.id,
        });
        const TaskId = currentEvent.meta.task_id;

        axios
          .get(`${REACT_APP_PUBLIC_API}/tasks/${TaskId}`, {
            headers: {
              Authorization: token,
            },
          })
          .then((res) => {
            selectTask(updateObject(res.data.data));
          })
          .catch((err) => {
            console.log(err);
          });
      }
    };
  }, [events, data]);
  const handleDrop = (e) => {
    e.preventDefault();
    let data;
    try {
      data = JSON.parse(e.dataTransfer.getData("text/plain"));
    } catch (e) {
      return;
    }
    const date = roundUpMinutes(scheduler.getActionData(e).date);
    const tmpId = `not-exists-on-server-${getRandomInt(0, 1000000)}`;
    scheduler.addEventNow({
      id: tmpId,
      task_id: data.id_task,
      text: data.text,
      start_date: date,
      end_date: scheduler.date.add(date, 1, "hour"),
      entity: { type: data.type, id: tmpId },
      meta: {
        task_priority_id: data.priority_id,
        task_status_id: data.status_id,
        task_id: data.id_task,
      },
      isLoading: true,
    });

    fetchData
      .post(
        `/api/v1/day/event-tasks`,
        JSON.stringify({
          owner_id: params.id,
          task_id: data.id_task,
          start_at: convertDateToUTC(date),
          end_at: convertDateToUTC(scheduler.date.add(date, 1, "hour")),
        })
      )
      .then((ans) => {
        if (ans) {
          scheduler.addEvent({
            id: `EVENT_TASK_TYPE-${ans.id}`,
            task_id: data.id_task,
            text: data.text,
            start_date: date,
            end_date: scheduler.date.add(date, 1, "hour"),
            entity: { type: data.type, id: ans.id },
            meta: {
              task_priority_id: data.priority_id,
              task_status_id: data.status_id,
              task_id: data.id_task,
            },
            isLoading: false,
          });
        }
      })
      .catch((error) => CreateNotif(error))
      .finally(() => scheduler.deleteEvent(tmpId));
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  return (
    <>
      <Header
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        setView={setView}
        view={view}
        setIsTaskView={setIsTaskView}
        setIsShowAddObject={setIsShowAddObject}
        setCurrentUser={setCurrentUser}
      />
      <div style={{ display: "flex" }}>
        <div
          id="scheduler_here"
          ref={refA}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          className="dhx_cal_container"
          style={{
            width: "100%",
            background: "#F4F4F4",
            paddingTop: "23px",
          }}
        >
          <div className="dhx_cal_navline">
            <div className="dhx_date_container">
              <div className="dhx_cal_prev_button">&nbsp;</div>
              <div className="dhx_cal_next_button">&nbsp;</div>
              <div className="dhx_cal_today_button" />
              <div className="dhx_cal_date" />
            </div>
            <div className="dhx_cal_tab" />
            <div className="dhx_cal_tab" />
            <div className="dhx_cal_tab" />
          </div>
          <div className="dhx_cal_header" />
          <div className="dhx_cal_data" />
        </div>
        <div>
          <Menu
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            setIsTaskView={setIsTaskView}
            currentView={view}
            setCurrentView={setView}
            setIsShowAddObject={setIsShowAddObject}
            events={events}
            setFilter={setFilter}
            filter={filter}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: State) => {
  return {
    selectedTask: state.taskInfoPlate.selectedTask,
  };
};

const mapDispatchToProps = {
  selectTask,
  setCellTaskData,
};

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(Scheduler);
