export enum errorMessagesConstants {
  GET_ONE_PROJECT = 'Произошла ошибка при загрузки проекта...',
  GET_RELOADED_PROJECTS = 'Произошла ошибка при перезагрузки проектов...',
  FIND_PROJECTS = 'Произошла ошибка при поиске проектов...',
  GET_TASK_STATISTICS = 'Произошла ошибка при загрузки статистики...'
}

export enum messagesTaskConstants {
  DELETE_TASK_ERROR = 'Произошла ошибка при удалении задачи...',
  DELETE_TASK_SUCCESS = 'Задача удалена',
  DELETE_TASK_STACK_SUCCESS = 'Стек связанных задач удален'
}

export enum messagesProjectConstants {
  DELETE_PROJECT_ERROR = 'Произошла ошибка при удалении проекта...',
  DELETE_PROJECT_SUCCESS = 'Проект удален',
}

export enum tooltipsTitle {
  DOWNLOAD_EXCEL_FILE = 'Скачать excel-файл со списком задач',
  SHOW_CALENDAR = 'Показать календарь',
  SELECT_COLUMNS = 'Выбрать колонки',
  BP_IN_WORK = 'Бизнес-процессы в работе',
  BP_DRAFT = 'Черновики бизнес-процессов',
  ADDITIONAL_ACTIONS = 'Дополнительные действия...',
  UPLOAD_FORM_FILE = 'Загрузить заполненную форму группового добавления сотрудников',
  DOWNLOAD_FORM_FILE = 'Скачать форму для группового добавления сотрудников'
}

export enum datePicker {
  DATE_FORMAT = 'DD-MM-YYYY HH:mm:ss',
  DATE_OPEN_FORMAT = 'MM/DD/YYYY HH:mm:ss',
  DATE_MIN_DEFAULT = '01-01-1950 00:00:00',
  DATE_MAX_DEFAULT = '01-01-2100 00:00:00',
  YEAR_MIN_DEFAULT = '1950',
  YEAR_MAX_DEFAULT = '2100',
}

export enum deleteTaskStatuses {
  DELETE_TASK = 0,
  DELETE_TASK_ALLOWED = 'allowed',
  DELETE_TASK_ERROR = 'error'
}

export enum deleteProjectStatuses {
  DELETE_PROJECT_TASK = 0,
  DELETE_PROJECT_BP = 1,
  DELETE_PROJECT_TASK_AND_BP = 3,
  DELETE_PROJECT_ERROR = 'error'
}


export enum pagesName {
  PROJECTS_PAGE = '/projects'
}

export enum taskStatuses {
  IN_WORK = 10,
  COMPLETED = 13,
  OVERDUE = 14
}

export enum Roles {
  ADMIN = 1,
  DIRECTOR = 2
}

export const roleDirector = 2;

export enum CheckContexts {
  IS_USER_CAN_EDIT_AND_SAVE_PROJECT = 'IS_USER_CAN_EDIT_AND_SAVE_PROJECT',
  IS_USER_CAN_SET_PROJECT_OBSERVERS = 'IS_USER_CAN_SET_PROJECT_OBSERVERS',
  IS_USER_CAN_SET_TASK_OBSERVERS = 'IS_USER_CAN_SET_TASK_OBSERVERS'
}

export const defaultHeaders = {
  FROM_WEB: {TITLE: 'FromWeb', VALUE: 'true'}
}
