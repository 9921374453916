import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import './styles.sass'
import { setSearchClear, getSearchProjects } from "../../actions";
import { useLocation } from "react-router-dom";
import { setSearchDirty } from "../../../pages/Projects/actions";

type Props = {
    searchValue?: string,
    isOpen?: boolean,
    setSearchValue: (value: string) => void
}

const TableSearch: React.FC<Props> = ({ isOpen=false, setSearchValue}) => {
    const dispatch = useDispatch();
    const location = useLocation();

    const [searchMode, setMode] = useState<boolean>(isOpen);
    const [value, setValue] = useState({ value: '' });

    const input = useRef<HTMLInputElement>(null);

    useEffect(() => {
        setValue(val => ({ ...val, value: '' }));
    }, [location.pathname]);

    const handleChangeMode = () => {
      setMode(true);
      setTimeout(() => input.current?.focus(), 0);
    };
    
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(val => ({
        ...val,
        value: e.target.value
      }));
      
      if(location.pathname.includes('/projects')) {
        dispatch(getSearchProjects(e.target.value));
        dispatch(setSearchDirty(true));
      }
      else {
        setSearchValue(e.target.value);
      }
    };
    
    const handleClear = (e: React.MouseEvent) => {
        e.stopPropagation();
        setMode(false);
        
        if(location.pathname.includes('/projects')) {
          setValue(val => ({ ...val, value: '' }));

          dispatch(setSearchClear(true, true));
          dispatch(setSearchDirty(false));
        }
        else {
          setSearchValue('');
        }
    };
  
    return (
        <div onClick={() => handleChangeMode()} className={`table_search${searchMode ? ' in_search_mode' : ''}`}>
            {searchMode ? (
                <>
                    <input
                        ref={input}
                        value={value.value}
                        onChange={(e) => handleChange(e)}
                    />
                    <div onClick={(e) => handleClear(e)} className="close_search"/>
                </>
            ) : (
                <div>Поиск</div>
            )}
        </div>
    )
};

export default TableSearch;
