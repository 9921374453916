import { types, TasksAction } from "./action_types";
import {
  File,
  File as FileType,
  IMeta,
  Task,
  TimeSheet,
  TimeSheetCache,
} from "../../common/types";
import { Reducer } from "redux";
import { cookieMaster } from "../../utils/CookieMaster";
import { columnsOfTables } from "../../common/shared_data";
import { deleteTaskStatuses } from "../../common/constants";

let default_init_cols: string[] = [];

let localSt: any = localStorage.getItem("tasksTableCols");
if (localSt) {
  let parsed = JSON.parse(localSt);
  Object.keys(parsed).forEach((item) => {
    default_init_cols.push(parsed[item]);
  });
} else {
  columnsOfTables.forEach((item) => {
    if (item.columnId !== "executor_id") {
      default_init_cols.push(item.columnId);
    }
  });
}

const initialState = {
  privateToggle: false,
  statusWindow: true, // 571 задача redmine *********************************
  tasks: [] as Task[],
  notification_for_tasks: [] as any[],
  taskAddMode: false,
  filtersWindowShow: false,
  isLoading: true,
  tableFilter: "",
  filters: {
    filterParametersList: [
      { text: "Статус", value: 1 },
    ],
    appliedFilters: {
      authors: [],
      executors: [],
      statuses: [],
    },
    draft: 0,
    addNewRow: true,
    exec_auth: "",
  },
  selectedUserId: parseInt(cookieMaster.getCookie("user_id")),
  showVisualization: false,
  showColumnsWindow: false,
  visualizationData: [] as Task[],
  isShownDayClose: false,
  tasksOrder: { col_name: "end", order_direct: true },
  activeColumns: default_init_cols,
  taskDoneConfirmation: {
    showTaskDoneConfirmationWindow: false,
    short_version: false,
    taskId: 0,
  },
  closingDayWindowShow: false,
  closingDayWindowShowHidingSignal: true,
  timeSheet: [] as TimeSheet[],
  TimeSheetCache: [] as TimeSheetCache[],
  deleteTaskModal: {
    taskId: '',
    isDeleteModalOpen: false,
    isShowPreloader: false,
    isShowMenuPreloader: false,
    statusFromServer: {constraints: []}
  } as {
    taskId: number|string, 
    isDeleteModalOpen: boolean, 
    isShowPreloader: boolean|undefined,
    isShowMenuPreloader: boolean|undefined, 
    statusFromServer: {constraints: deleteTaskStatuses[]|undefined}
  },
  tasksMeta: {} as IMeta
};

export type TasksState = typeof initialState;

const reducer: Reducer<TasksState, TasksAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case types.LOCAL_TIMESHEET_RECORD:
      let newData;

      switch (action.actionType) {
        case "add":
          const found_task = state.timeSheet.find(
            (task) => task.task_id === action.taskId
          );

          if (found_task) {
            let copy_main_array = state.timeSheet;
            copy_main_array.splice(
              copy_main_array.findIndex(
                (task) => task.task_id === action.taskId
              ),
              1
            );

            if (action.task_load_sum !== undefined)
              found_task["task_load_sum"] = action.task_load_sum as number;
            if (action.status_id) found_task["status_id"] = action.status_id;
            if (action.commentText?.length)
              found_task["comment_text"] = action.commentText;
            if (action.commentFiles)
              found_task["comment_files"] = action.commentFiles;

            newData = [...copy_main_array, found_task];
          } else {
            newData = { task_id: action.taskId };

            if (action.task_load_sum)
              newData["task_load_sum"] = action.task_load_sum;

            if (action.status_id) newData["status_id"] = action.status_id;
            if (action.commentText?.length)
              newData["comment_text"] = action.commentText;
            if (action.commentFiles)
              newData["comment_files"] = action.commentFiles;
            newData = [...state.timeSheet, newData];
          }

          break;

        case "clear_whole":
          newData = [];
          break;
      }

      return {
        ...state,
        timeSheet: newData,
      };

    case types.TIMESHEET_CACHE_RECORD:
      // let result
      let result: TimeSheetCache[] = state.TimeSheetCache;

      switch (action.actionType) {
        case "write":
          let existing_item = state.TimeSheetCache.find(
            (item) => item.task_id === action.task_id
          );

          if (existing_item) {
            state.TimeSheetCache.splice(
              state.TimeSheetCache.findIndex(
                (item) => item.task_id === action.task_id
              ),
              1
            );
            if (action.task_load_sum !== undefined)
              existing_item["task_load_sum"] = action.task_load_sum;
            if (action.status_id !== undefined)
              existing_item["status_id"] = action.status_id;
            result.push(existing_item);

          } else {
            if (action.task_id) {
              let newRec = {
                task_id: action.task_id,
              };
              if (action.task_load_sum)
                newRec["task_load_sum"] = action.task_load_sum;
              if (action.status_id) newRec["status_id"] = action.status_id;
              result.push(newRec);
            }
          }
          break;

        case "clear":
          result = [];
          break;
      }

      return {
        ...state,
        TimeSheetCache: result,
      };

    case types.CHANGE_TASK_LIST:
      return {
        ...state,
        tasks: action.tasks,
      };
      
    case types.UPDATE_TASKS_STATE:
      return {
        ...state,
        tasks: [
          action.task,
          ...state.tasks
        ]
      };

    // 571 задача redmine *********************************
    //********************************************** */
    case types.UPDATE_STATUS_WINDOW_TRUE:
      return {
        ...state,
        statusWindow: true,
      };
    case types.UPDATE_STATUS_WINDOW_FALSE:
      return {
        ...state,
        statusWindow: false,
      };
    //************************************************** */

    case types.CLEAR_VISUALIZATION_LIST:
      return {
        ...state,
        visualizationData: [],
      };
    case types.CHANGE_VISUALISATION_LIST:
      return {
        ...state,
        visualizationData: action.tasks,
      };
    case types.SET_TASK_ADD_MODE:
      return {
        ...state,
        taskAddMode: action.mode,
      };
    case types.SET_FILTER_WIN_MODE:
      return {
        ...state,
        filtersWindowShow: action.mode,
        filters: Object.assign(
          {},
          state.filters,
          {
            addNewRow: !action.mode
              ? !Object.keys(state.filters.appliedFilters).length
              : state.filters.addNewRow,
          },
          { draft: 0 }
        ),
      };
    case types.SET_LOADING_TASK:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case types.SET_SELECTED_USER_ID:
      return {
        ...state,
        selectedUserId: action.id,
      };
    case types.SET_TABLE_FILTER:
      return {
        ...state,
        tableFilter: action.filter,
      };
    case types.SET_FILTERS:
      // пока что реализовано только для целочисленных значений внутри массива
      return {
        ...state,
        filters: Object.assign(
          {},
          state.filters,
          { appliedFilters: action.filterObject },
          { draft: 0 }
        ),
      };
    case types.SET_DRAFT_VALUE:
      return {
        ...state,
        filters: Object.assign(
          {},
          state.filters,
          { draft: action.draft },
          { addNewRow: false }
        ),
      };
    case types.SET_FILTERS_ADD_NEW_ROW:
      return {
        ...state,
        filters: Object.assign({}, state.filters, { addNewRow: action.value }),
      };
    case types.SET_SHOW_VISUALIZATION:
      return {
        ...state,
        showVisualization: action.show,
      };
    case types.SET_SHOW_COLUMNS_WINDOW:
      return {
        ...state,
        showColumnsWindow: action.show,
      };
    case types.SET_SHOW_DAYS_OFF:
      return {
        ...state,
        isShownDayClose: action.show,
      };
    case types.SET_PRIVATE_TOGGLE:
      return {
        ...state,
        privateToggle: action.privateToggle,
      };
    case types.SET_ALL_OR_ACTUAL:
      return {
        ...state,
        filters: Object.assign(
          {},
          state.filters,
          {
            exec_auth:
              action.value["exec_auth_value"] === "executor"
                ? state.filters.exec_auth === "author" &&
                  action.value["erase_applied_filters"]
                  ? state.filters.exec_auth
                  : "executor"
                : action.value["exec_auth_value"],
          },
          {
            appliedFilters: action.value["erase_applied_filters"]
              ? {}
              : state.filters.appliedFilters,
          },
          { addNewRow: !state.filters.draft }
        ),
      };
    case types.SET_EXEC_AUTH:
      return {
        ...state,
        filters: Object.assign({}, state.filters, { exec_auth: action.val }),
      };
    case types.SET_TABLE_ORDER:
      return {
        ...state,
        tasksOrder: {
          col_name: action.col_name,
          order_direct: action.order_direct,
        },
      };
    case types.NOTIFICATIONS_FOR_TASKS:
      return {
        ...state,
        notification_for_tasks: action.value,
      };

    case types.TASK_DONE_CONFIRMATION:
      return {
        ...state,
        taskDoneConfirmation: {
          showTaskDoneConfirmationWindow: action.show,
          taskId: action.taskId,
          short_version: action.short_version,
        },
      };

    case types.CLOSING_DAY:
      return {
        ...state,
        closingDayWindowShow: action.value,
      };

    case types.CLOSING_DAY_HIDING_SIGNAL:
      return {
        ...state,
        closingDayWindowShowHidingSignal: action.value,
      };

    case types.SET_SHOWING_COLS:
      const val = action.value;
      let new_arr: string[] = [];

      if (val === "DEFAULT" || val === "ALL") {
        if (val === "DEFAULT") {
          columnsOfTables.forEach((item) => {
            if (
              !(
                (
                  item.columnId === "project_name" ||
                  item.columnId === "section_name"
                )
              )
            ) {
              new_arr.push(item.columnId);
            }
          });
        } else columnsOfTables.forEach((item) => new_arr.push(item.columnId));
      } else {
        // копирую стэйт в новый объект, если вставлю старый, ререндера не произойдет
        state.activeColumns.forEach((item) => new_arr.push(item));
        new_arr.includes(val)
          ? new_arr.splice(new_arr.indexOf(val), 1)
          : new_arr.push(val);
      }

      localStorage.setItem("tasksTableCols", JSON.stringify({ ...new_arr }));

      return {
        ...state,
        activeColumns: new_arr,
      };

    case types.SET_DELETE_MODAL_DATA:
      return {
        ...state,
        deleteTaskModal: {
          ...state.deleteTaskModal,
          taskId: action.taskId,
          isDeleteModalOpen: action.isDeleteModalOpen,
          isShowPreloader: action.isShowPreloader,
          isShowMenuPreloader: action.isShowMenuPreloader,
          statusFromServer: action.statusFromServer
        }
      };

    case types.DELETE_TASK_FROM_STATE:
      return {
        ...state,
        tasks: state.tasks.filter(task => task.id !== action.id)
      };

    case types.SET_TASKS_META:
      return {
        ...state,
        tasksMeta: action.tasksMeta
      }

    default:
      return state;
  }
};

export default reducer;
