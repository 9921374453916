
import React from 'react';
import styled from 'styled-components';

type Props = {
    activity: boolean;
};

const ActivityBlockStyle = styled.div`
  max-width: 200px;
  min-width: 200px;
  padding-left: 20px;
  white-space: nowrap;
`;

const UserActivityCell: React.FC<Props> = ({ activity }) => {
  return <ActivityBlockStyle>{activity?"Активен":"Неактивен"}</ActivityBlockStyle>;
};

export default UserActivityCell;
