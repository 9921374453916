/**********   Главный модуль   ***************** */
import React, { useEffect, useCallback, useRef, useState } from "react";
import CommonInfoPreloader from "./CommonInfoPreloader/CommonInfoPreloader";
import Header from "./Header/Header"; // Меню слева
import ProjectAddPanel from "../../pages/NewProject/index";
import TaskAddPanel from "../../pages/NewTask/index";
import UserAddPanel from "../../pages/NewUser/index";
import UserChangePasswordWindow from "../../pages/NewUser/components/UserChangePasswordWindow";
import { connect, useDispatch } from "react-redux";
import TaskInfoPlate from "../../pages/TaskInfoPlate/index";
import { State } from "../../rootReducer";
import {
  Route,
  useHistory,
  Switch,
  Redirect,
  useLocation,
} from "react-router-dom";
import Tasks from "../../pages/Tasks";
import Projects from "../../pages/Projects/Project";
import ProjectAll from "../../pages/Projects/ProjectAll";
import Payment from "../../pages/Payment";
import Users from "../../pages/Users";
import Calendar from "../../pages/Day";

import Bp from "../../pages/Bp/src/App";
import CompanyDetails from "../../pages/Company/CompanyDetails";
import Meetings from "../../pages/Meetings";

import { cookieMaster } from "../../utils/CookieMaster";
import { fetchCommonInfo } from "../actions";
import { Project, User } from "../types";
import AddDepPlate from "../../pages/Users/components/AddDepPlate";
import {
  setAddUserToGroupDep,
  setDepGroupType,
} from "../../pages/Users/actions";
import AddUsersPlate from "../../pages/Users/components/AddUsersPlate/AddUsersPlate";
import RepeatCustomWindow from "../../pages/TaskInfoPlate/components/RepeatCustomWindow";
import ConfirmEditRepeatTask from "../../pages/TaskInfoPlate/components/ConfirmEditRepeatTask";
import TaskDone from "../../pages/Tasks/components/TaskDone";
import ClosingDay from "../../pages/Tasks/components/ClosingDay";
import { fetchData } from "../../utils/fetchData";
import CompanyBlock from "../../pages/Company";
import Faq from "./Faq";
import BusinessProcessesModal from "../../pages/Projects/components/BusinessProcessesModal";
import DependenciesWindow from "../../pages/TaskInfoPlate/components/DependenciesWindow";
import MeetingRightPlate from "../../pages/Old_Meetings/components/RightPlate";
import styled from "styled-components"; //******************************************************* */
import {setSearchMeetings} from '../actions'
import HeaderAvatar from "../components/Header/HeaderAvatar";
import NotificationButton from "../components/Header/NotificationButton";
import { Search } from "@material-ui/icons";
import TableSearch from "../../common/components/TableSearch/TableSearch";
import { setTableFilter } from "../../pages/Tasks/actions";
import { setTableFilter as setTableFilterProject } from "../../pages/Projects/actions";
import { selectProject } from "../../pages/Projects/actions";
import { Task } from "../../common/types";
import { changeTaskList, fetchTasks } from "../../pages/Tasks/actions";

import { useContext } from "react";
import { useStateContext } from "../../useStateContext";
import PaymentMethod from "../../pages/Payment/Components/PaymentMethod";
import SuccessfulPayment from "../../pages/Payment/Components/SuccessfulPayment";
import CheckPayment from "../../pages/Payment/Components/CheckPayment";
import UnsuccsefulPayment from "../../pages/Payment/Components/UnsuccsefulPayment";
import axios from "axios";

import {
  changeTaskList as changeTaskListProject,
  fetchTasks as fetchTasksProject,
} from "../../pages/Projects/actions";
import CommonCirclePreloader from "./CommonInfoPreloader/CommonCirclePreloader";
import { CreateNotif } from "../../utils/createNotification";
import { Filter } from "../../pages/Meetings/Filter";
import DeleteTaskModal from "../../pages/Tasks/components/ui/Modal/Modal";
import DeleteProjectModal from "../../pages/Projects/components/DeleteModalWindow/Modal";


/*  ***************************
Цвета:
Синий: #99e6ff
Светло-синий: #ccf2ff
Цвет из макета: #01B0E9
*/
//******************************************** */

const SearchDiv = styled.div`
  width: 288px;
  height: 34px;
  top: calc(50% - 34px / 2 - 509px);
  background: #4bb5eb;
  border-radius: 4px;
  display: flex;
  align-items: center;
`;

const SpanMail = styled.span`
  text-decoration: underline;
  margin-left: 5px;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
`;

const SearchInput = styled.input`
  width: 233px;
  height: 17px;
  top: 18px;
  color: #cae8ff;
  background: #4bb5eb;
  margin-left: 10px;
`;

// Горизонтальное верхнее меню
const HeaderHorizontal = styled.header`
  /* margin: 0 auto; */
  /* max-width: 1280px; */
  display: flex;
  align-items: center;
  justify-content: flex-end; //**************************************************************
  height: 63px;
  border-bottom: 1px solid #e1e1e7;
  padding: 0 40px;
  background: #01b0e9; /****/
`;

const Preloader = styled.div`
  &:before {
        content: '';
        background: #fff;
        z-index: 3;
        // width:100%;
        // height: 100%;
        position:absolute;
        // top:-5px;
        right:0;
  }
      &:after {
        content: '';
        border-radius: 50%;
        border: 3px solid #1BAAF0;
        z-index: 3;
        border-bottom-color: transparent;
        border-left-color: transparent;
        animation: spin .5s linear infinite;
        transform: translateX(-50%);
        position:absolute;
        top:0;
        right:-31px;
        width:16px;
        height:16px;
  `;
//******************************************* */

type Props = {
  projectId: number | null;
  changeTaskList: (tasks: Task[]) => void;
  changeTaskListProject: (tasks: Task[]) => void;
  fetchTasks: (pageNum: number) => void;
  fetchTasksProject: (projectId: number, pageNum: number) => void;
  projectAddMode: boolean;
  projectSectionAddMode: boolean;
  taskAddMode: boolean;
  userAddMode: boolean;
  isShownTaskInfoPlate: boolean;
  isShownDayClose: boolean;
  currentId: number;
  projects: Project[];
  fetchCommonInfo: () => void;
  changePassWindowShow: boolean;
  // show_notification_window: boolean,
  setDepGroupType: (value: string | null) => void;
  showAddUserToGroupDep: boolean;
  showWindowCreateDepGroup: boolean;
  plateType: string | null;
  selectedDepGroupId: null | number;
  repeat_custom_window: boolean;
  showEditCyclicTaskWindow: {};
  showTaskDoneConfirmationWindow: boolean;
  closingDayWindowShow: boolean;
  businessProcessesModalShow: boolean;
  isDependencies: boolean;
  selectedProject: Project | null;
  show_company_window: boolean;
  showMeetingRightPlate: boolean;
  tableFilter: string;
  tableFilterProject: string;
  setTableFilter: (filter: string) => void;
  setTableFilterProject: (filter: string) => void;
  currentUserInfo: null | User;
  searchMeetings: string|null;
  setSearchMeetings: (srch: string|null)=>void;
};

const Main: React.FC<Props> = ({
  projectId,
  tableFilter,
  tableFilterProject,
  changeTaskList,
  changeTaskListProject,
  fetchTasks,
  fetchTasksProject,
  setTableFilter,
  setTableFilterProject,
  setDepGroupType,
  repeat_custom_window,
  selectedDepGroupId,
  isDependencies,
  plateType,
  showWindowCreateDepGroup,
  showAddUserToGroupDep,
  //    show_notification_window,
  taskAddMode,
  projectAddMode,
  projectSectionAddMode,
  userAddMode,
  isShownTaskInfoPlate,
  currentId,
  projects,
  isShownDayClose,
  fetchCommonInfo,
  changePassWindowShow,
  showEditCyclicTaskWindow,
  showTaskDoneConfirmationWindow,
  closingDayWindowShow,
  businessProcessesModalShow,
  selectedProject,
  show_company_window,
  showMeetingRightPlate,
  currentUserInfo,
  searchMeetings,
}) => {
  const { warningWindow, setWarningWindow, anketa_send, setAnketa_send } = useContext(useStateContext);
  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  const [one, setOne] = useState(true);

  function handleChange(event) {
    setValue(event.target.value);
  }

  let history = useHistory();
  let jiva = document.getElementById("jvlabelWrap");

  const location = useLocation();
  const [isProjectsPage, setIsProjectsPage] = useState(true);

  useEffect(() => {
    if (localStorage.getItem("release_version") !== "3.1") {
      cookieMaster.deleteCookie("access_token");
      cookieMaster.deleteCookie("access_token_jwt");
      cookieMaster.deleteCookie("login");
      cookieMaster.deleteCookie("company_id");
      cookieMaster.deleteCookie("user_id");

      localStorage.clear();
      history.push("/login");
    }
  });

  useEffect(() => {
    if (currentUserInfo && !currentUserInfo.anketa_check && !anketa_send) {

      let headers = {
        Authorization: cookieMaster.getCookie("access_token"),
        "company-id": 1,
      };
    
      const getConfig = {
        method: "get",
        url: `${process.env.REACT_APP_PUBLIC_API}/anketa`,
        headers: headers,
      };
      axios(getConfig)
      .then((res) => {
       if ( res && res?.data && res?.data?.data && res?.data?.data?.length>0 ) history.push("/questionnaire");
      })
      .catch((e) => console.log("ошибка получения анкеты", e));

    }

    
  }, [currentUserInfo]);

  useEffect(() => {
    if (location.pathname.includes("/projects")) {
      setIsProjectsPage(true);
    } else {
      setIsProjectsPage(false);
    }
  }, [location.pathname]);

  // useEffect(() => {
  //   if (one) {
  //     CreateNotif("Письмо отправлено", "success");
  //   }
  // }, [one]);

  if (jiva) jiva.style.display = "none";
  useEffect(() => {
    let token = cookieMaster.getCookie("access_token");
    let company_id = localStorage.getItem("company_id") || "";
    let company_id_old = company_id;
    if (token) {
      fetchData
        .get(`/api/v1/users/${currentId}/companies`)
        .then((data) => {
          if (data !== undefined) {
            if (data.length === 0) {
              let x = [0];
              return x;
            } else {
              return data;
            }
          } else {
            return [0];
          }
        })
        .then((company) => {
          if (+company_id === 0 || company_id === "") {
            if (company.length > 0 && company[0] !== 0) {
              company_id =
                company.find((id) => id === +company_id) || company[0];
              localStorage.setItem("company_id", company_id);
              window.location.replace("/");
            } else if (company[0] === 0) {
              localStorage.setItem("company_id", "0");
              window.location.replace("/register");
            }
          }
          if (+company_id > 0) {
            // console.log('заходит', company[0]);
            company_id =
              company.find((id) => id === +company_id) || company[0] || "";
            localStorage.setItem("company_id", company_id);
            fetchCommonInfo();
            if (company_id != company_id_old) {
              window.location.reload();
            }
          }
        })
        .catch((error) => {
          throw new Error(error);
        });
    } else {
      history.push("/login");
    }
    // if (token) {
    //   fetchData
    //     .get(`/api/v1/users/${currentId}/companies`)
    //     .then((data) => {
    //       if (data === undefined) {
    //         cookieMaster.deleteCookie('access_token');
    //         localStorage.clear();
    //         window.location.replace('/login');
    //       }

    //       if (+company_id === 0) {
    //         if (data) {
    //           console.log(data);
    //           company_id =
    //             data.find((id) => id === +company_id) || data[0] || '';
    //           localStorage.setItem('company_id', company_id);
    //           window.location.replace('/');
    //         } else {
    //           window.location.replace('/register');
    //         }
    //       }
    //       if (+company_id > 0) {
    //         company_id = data.find((id) => id === +company_id) || data[0] || '';
    //       }
    //     })
    //     .then(() => {
    //       localStorage.setItem('company_id', company_id);
    //       window.location.replace('/');
    //     })
    //     .then(() => {
    //       if (company_id === '') {
    //         fetchData
    //           .get(`/api/v1/users/${currentId}/companies`)
    //           .then((data) => {
    //             company_id = data?.sort((a, b) => a - b)[0] || '0';
    //             console.log(company_id);
    //           })
    //           .then(() => {
    //             console.log(company_id);
    //             localStorage.setItem('company_id', company_id);
    //           })
    //           .then(() => window.location.replace('/'))
    //           .catch((error) => {
    //             cookieMaster.deleteCookie('access_token');
    //             localStorage.clear();

    //             window.location.replace('/');
    //           });
    //       } else if (+company_id === 0) {
    //         history.push('/register');
    //       } else if (+company_id > 0) {
    //         fetchCommonInfo();
    //       }
    //     })
    //     .catch((error) => {
    //       cookieMaster.getCookie('access_token');
    //       throw new Error(error);
    //     });
    // } else {
    //   history.push('/login');
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const blur =
    projectAddMode ||
    projectSectionAddMode ||
    taskAddMode ||
    // userAddMode ||
    // isShownDayClose ||
    showWindowCreateDepGroup ||
    showAddUserToGroupDep ||
    repeat_custom_window ||
    showTaskDoneConfirmationWindow ||
    businessProcessesModalShow ||
    Boolean(Object.keys(showEditCyclicTaskWindow).length);

  //******************************************************* */
  // широкое левое меню или поменьше
  // Если isLeftBlockBig = true - ширина левого меню 286 px
  // Если isLeftBlockBig = false - ширина левого меню 146 px
  // В случае узкого меню надписи "Задачи", "проекты", "Сотрудники" скрываются
  // надпись над ними вместо "Easy Task" остается "E"
  const [isLeftBlockBig, setIsLeftBlockBig] = useState<boolean>(true);
  //******************************************************* */

  const [timer, setTimer] = useState<ReturnType<typeof setTimeout> | null>(null);

  const searchChangeHandler = useCallback((filter: string) => {
      if (location.pathname.includes("/tasks")) {
        setTableFilter(filter);
        if (timer) clearTimeout(timer);
        setTimer(
          setTimeout(() => {
            changeTaskList([]);
            fetchTasks(1);
          }, 500)
        );
      }
      
      /// meetings search
      if (location.pathname.includes("/meetings")) {
        setTableFilter(filter);
        if (timer) clearTimeout(timer);
        setTimer(
          setTimeout(() => {
            dispatch(setSearchMeetings(filter));
          }, 500)
        );
      }
    }, [changeTaskList, fetchTasks, setTableFilter, timer, location.pathname, setSearchMeetings, setTableFilterProject]
  );

  useEffect(() => {
    setTableFilter('');
    setSearchMeetings('');
  }, [location.pathname]);

  useEffect(() => {
    // setWarningWindow(true)
    let user_id_string = localStorage.getItem("user_id");
    if (user_id_string) {
      fetchData
        .get(`/api/v1/users/${user_id_string}`)
        .then((data) => {
          // console.log("data***", data.verify_status);
          if (data.verify_status === false) {
            setWarningWindow(true);
          }
        })
        .catch((error) => console.log(error));
    }
  }, []);

  return (
    <>
      <main className={`${blur ? "blur" : ""}`}>
        <CommonInfoPreloader />
        {/* ***************************************** */}
        <div style={{ display: "flex", justifyContent: "left" }}>
          {/* ***************************************** */}
          {/* Вертикальное меню слева. Передаем туда выбранный размер меню */}
          <Header
            isLeftBlockBig={isLeftBlockBig}
            setIsLeftBlockBig={setIsLeftBlockBig}
            isProjectsPage={isProjectsPage}
          />
          <div
            style={{
              display: "block",
              minHeight: "100vh",
              width: isLeftBlockBig
                ? `calc(100vw - 286px)`
                : `calc(100vw - 146px)`,
            }}
          >
            {warningWindow && (
              <div
                style={{
                  backgroundColor: "#292A34",
                  height: "56px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingLeft: "15px",
                  paddingRight: "15px",
                  position: "relative",
                }}
              >
                <div style={{ color: "#fff" }}></div>
                <div
                  style={{
                    color: "#fff",
                    display: "flex",
                    position: "relative",
                  }}
                >
                  <img
                    style={{ marginRight: "10px" }}
                    src={`${process.env.REACT_APP_PUBLIC_URL}/assets/warning.svg`}
                  />
                  <p
                    style={{
                      color: "yellow",
                      marginTop: "6px",
                    }}
                  >
                    Email не подтвержден. Функционал ограничен.
                    <SpanMail
                      onClick={() => {
                        setOne(false);
                        fetchData
                          .post(`/api/v1/repeat_verify`)
                          .then((res) => {
                            if (res.result) {
                              CreateNotif(res.message, "success");
                            } else {
                              CreateNotif(res.message);
                            }
                            setOne(true);
                          })
                          .catch((res) => {
                            CreateNotif(res.message);
                            setOne(true);
                          });
                      }}
                    >
                      Запросить подтверждение еще раз
                      {!one && <Preloader />}
                    </SpanMail>
                  </p>
                </div>
                <div>
                  <img
                    onClick={() => {
                      setWarningWindow(false);
                    }}
                    src={`${process.env.REACT_APP_PUBLIC_URL}/assets/closeButton.svg`}
                  />
                </div>
              </div>
            )}

            {/* Верхнее (Горизонтальное) меню */}
            <HeaderHorizontal>

              {/* Временно отключение поиска на странице Проекты; на станице Календарь - постоянно отключен поиск */}
              {(!location.pathname.includes('/projects') && !location.pathname.includes('/calendar')) &&
                <div style={{ display: "flex", right: "160px" }}>
                  <TableSearch
                    searchValue={tableFilter ? tableFilter : tableFilterProject}
                    setSearchValue={searchChangeHandler}
                  />
                </div>
              }
              {/*
                <SearchDiv>
               
                <Search style={{color: "#FFFFFF", marginLeft: "10px"}}/>
                <SearchInput placeholder="Поиск" color="#fff" placeholderTextColor={'red'} value={value} onChange={handleChange} />
                </SearchDiv>
                */}

              {/* "Колокольчик" в верхнем меню- кнопочка вывода сообщений */}
              <NotificationButton />

              {/* Профиль пользователя */}
              <HeaderAvatar />
            </HeaderHorizontal>
            {/* ****************************************** */}

            <Switch>
              <Route path="/tasks/:id" component={Tasks} />
              {/*<Route path="/tasks/:id/:task_id" component={Tasks}/>*/}
              <Route
                exact
                path="/tasks"
                render={() => <Redirect to={`/tasks/${currentId}`} />}
              />

              {/* <Route
                exact
                path="/projects"
                render={() => {
                  if (projects.length) {
                    return (
                      <Redirect
                        to={`/projects/${
                          selectedProject
                            ? selectedProject?.id
                            : projects[0]?.id || 0
                        }`}
                      />
                    );
                  } else {
                    return <Projects />;
                  }
                }}
              /> */}
              {/* {console.log(selectedProject, projects)} */}
              {/* watchFetchProjectTasks */}

              {/* Rout-ы для Easy Meet */}
              <Route
                path={["/meetings/:id", "/meetings"]}
                component={Meetings}
              />
              {/*                      */}

              <Route
                path={["/meetings/:id", "/meetings"]}
                component={Meetings}
              />
              <Route exact path={"/projects"} component={ProjectAll} />
              <Route path={"/projects/:id"} component={Projects} />

              <Route path={"/payment/method"} component={PaymentMethod} />

              <Route
                path={"/payment/offline-success/:id"}
                component={CheckPayment}
              />

              <Route
                path={"/payment/online-success"} //не менять. банк направляет на этот адрес после оплаты
                component={SuccessfulPayment}
              />

              <Route path={"/payment/failed"} component={UnsuccsefulPayment} />

              <Route path={"/payment"} component={Payment} />
              <Route path={"/CompanyDetails"} component={CompanyDetails} />

              <Route path={["/users/:id", "/users"]} component={Users} />

              <Route path="/calendar/:id" component={Calendar} />
              <Route
                exact
                path="/calendar"
                render={() => <Redirect to={`/calendar/${currentId}`} />}
              />

              <Route path="/bp/" component={Bp} />
            </Switch>
          </div>
          {/* ***************************************** */}
        </div>
        {/* ***************************************** */}
        {/* {console.log(show_company_window)} */}
        {show_company_window && <CompanyBlock />}
        <TaskInfoPlate />
        {/* {showMeetingRightPlate && <MeetingRightPlate />} */}
        {/*<ClosingDay />*/}
        {/* {isShownTaskInfoPlate && <TaskInfoPlate />} */}
        {closingDayWindowShow && <ClosingDay />}

        <DeleteTaskModal />
        <DeleteProjectModal />
      </main>

      {projectAddMode && <ProjectAddPanel />}
      {projectSectionAddMode && <ProjectAddPanel />}
      {taskAddMode && <TaskAddPanel />}
      {userAddMode && <UserAddPanel />}

      {showWindowCreateDepGroup && (
        <AddDepPlate
          setShow={() => {
            // если ни группа ни отдел сейчас не активны - то можно плашку обнулить. В противном случае - нельзя.
            if (selectedDepGroupId === null) setDepGroupType(null);
          }}
        />
      )}
      {showAddUserToGroupDep && (
        <AddUsersPlate
          setShow={() => {
            setAddUserToGroupDep(false);
          }}
        />
      )}

      {showTaskDoneConfirmationWindow && <TaskDone />}

      {Boolean(repeat_custom_window) && <RepeatCustomWindow />}

      {Object.keys(showEditCyclicTaskWindow).length > 0 && (
        <ConfirmEditRepeatTask />
      )}

      {businessProcessesModalShow && <BusinessProcessesModal />}

      {changePassWindowShow && <UserChangePasswordWindow />}

      {/* <Faq /> */}
      {isDependencies && <DependenciesWindow />}
    </>
  );
};

const mapStateToProps = (state: State) => {
  return {
    tableFilter: state.tasksPage.tableFilter,
    tableFilterProject: state.projectsPage.tableFilter,
    selectedDepGroupId: state.usersPage.selectedDepGroupId,
    showWindowCreateDepGroup: state.usersPage.showWindowCreateDepGroup,
    showAddUserToGroupDep: state.usersPage.showAddUserToGroupDep,
    plateType: state.usersPage.depGroupPlateType,

    selectedProject: state.projectsPage.selectedProject,

    showMeetingRightPlate: state.meetingsPage.showRightPlate,

    projectAddMode: state.projectsPage.projectAddMode,
    projectSectionAddMode: state.projectsPage.projectSectionAddMode,
    taskAddMode: state.tasksPage.taskAddMode,
    userAddMode: state.usersPage.userAddMode,
    isShownTaskInfoPlate: state.taskInfoPlate.isShownTaskInfoPlate,
    isShownDayClose: state.tasksPage.isShownDayClose,
    currentId: state.commonInfo.current_user,
    projects: state.commonInfo.projects,
    show_company_window: state.commonInfo.show_company_window,
    closingDayWindowShow: state.tasksPage.closingDayWindowShow,
    changePassWindowShow: state.newUser.changePassWindowShow,
    repeat_custom_window: state.taskInfoPlate.repeat.show_modal_window,
    showEditCyclicTaskWindow:
      state.taskInfoPlate.rememberObjectCyclicTaskToChange,
    showTaskDoneConfirmationWindow:
      state.tasksPage.taskDoneConfirmation.showTaskDoneConfirmationWindow,
    businessProcessesModalShow: state.projectsPage.businessProcessesModalShow,
    isDependencies: state.taskInfoPlate.isDependencies,
    currentUserInfo: state.commonInfo.currentUserInfo,
    searchMeetings: state.commonInfo.searchMeetings,
    projectId: state.projectsPage.selectedProject?.id || null,
  };
};

const mapDispatchToProps = {
  fetchTasks,
  fetchTasksProject,
  changeTaskListProject,
  changeTaskList,
  setTableFilter,
  setTableFilterProject,
  fetchCommonInfo,
  setDepGroupType,
  selectProject,
  setSearchMeetings
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
