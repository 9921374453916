import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import {State} from '../../../rootReducer';
import Button from "../../../common/components/newComponent/Buttons/Button";
import {Task} from "../../../common/types";
import {
    clearCyclicTaskToChange,
    sendReqToChangeTemplRepTask,
    setCyclicTaskToChange,
    setFlagRepeatTaskToChange,
    setStartCyclick
} from "../actions";
import { setIdActiveTask, updateTask } from "../../../common/actions";

type Props = {
    repeat: any,
    setStartCyclick: (period?: string, params?: Array<number>, interval?: number, show_modal_window?: boolean, stringForMainPart?: string) => void,
    setFlagRepeatTaskToChange: (val: string) => void,
    selectedTask: Task | null;
    rememberObjectCyclicTaskToChange: {};
    updateTask: (id: string|number, params: any) => void;
    setCyclicTaskToChange: (val: {}) => void;
    setIdActiveTask: (idActiveTask: string|number) => void;
    clearCyclicTaskToChange: () => void,
    idActiveTask: string|number
}

const ConfirmEditRepeatTask: React.FC<Props> = ({
    rememberObjectCyclicTaskToChange,
    updateTask,
    clearCyclicTaskToChange,
    idActiveTask,
    setIdActiveTask
}) => {
    const standardAction = () => {
        setIdActiveTask('');

        updateTask(idActiveTask, rememberObjectCyclicTaskToChange);
        clearCyclicTaskToChange();
    }

    return (
        <div className="add_pannel_wrapper">
            <div className={`confirm_edit_repeat_task_window`}>
                <div className={'firstRow'}>
                    <div>Изменить повторяющуюся задачу ?</div>
                </div>
                <div className={'secondRow'}>
                    Вы изменяете повторяющуюся задачу. Изменить только эту задачу, или все ее повторения в будущем ?
                </div>
                <div className={'bottomRow'}>
                    <Button title={'Все будущие'} design={'secondary'} onClick={() => {
                        sendReqToChangeTemplRepTask(rememberObjectCyclicTaskToChange);  // послать на сервер сигнал о изменении для всех последующих
                        standardAction()
                    }}/>
                    <Button title={'Только эту задачу'} onClick={() => {
                        standardAction()
                    }}/>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state: State) => {
    return {
        selectedTask: state.taskInfoPlate.selectedTask,
        repeat: state.taskInfoPlate.repeat,
        rememberObjectCyclicTaskToChange: state.taskInfoPlate.rememberObjectCyclicTaskToChange,
        idActiveTask: state.commonInfo.idActiveTask
    }
}

const mapDispatchToProps = {
    setStartCyclick,
    setFlagRepeatTaskToChange,
    updateTask,
    setCyclicTaskToChange,
    clearCyclicTaskToChange,
    sendReqToChangeTemplRepTask,
    setIdActiveTask
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmEditRepeatTask);
